import { FunctionComponent, ReactNode, useState } from 'react';

interface ReadMoreProps extends React.HTMLAttributes<HTMLDivElement> {
  maxChars?: number;
  children: ReactNode;
}

const ReadMore: FunctionComponent<ReadMoreProps> = ({ maxChars = 150, children, ...rest }: ReadMoreProps) => {
  const text = String(children);
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className={rest.className}>
      {isReadMore ? text.slice(0, maxChars) : text}
      {text.length > maxChars && (
        <span onClick={toggleReadMore} className="cursor-pointer text-gray-900 hover:font-bold">
          {isReadMore ? ' ...read more' : ' show less'}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
