/**
 * Helper to produce a string based on an array.
 * Mostly used for html elements className attribute.
 * @param {string[]} classes Enumeration object.
 * @return {string} A string with all the classes joined
 */
export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
};

type Descripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly description: string;
  };
}[keyof T];

/**
 * Helper to produce an array of enum descriptors.
 * @param {T} enumeration Enumeration object.
 * @param {RegExp} separatorRegex Regex that would catch the separator in your enum key.
 * @return {Descripted<T>[]} An array with the values
 */
export function enumToDescriptedArray<T>(enumeration: T, separatorRegex: RegExp = /_/g): Descripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => isNaN(Number(key)))
    .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map(key => ({
      id: enumeration[key],
      description: String(key).replace(separatorRegex, ' '),
    }));
}

/**
 * Helper to convert a camelCase string into a sentence string.
 * @param {string} text CamelCase text.
 * @return {string} A string in the "Sentence case" format
 */
export const camelToSentence = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toSentenceCase = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.substring(1, text.length);
};

/**
 * Helper to convert a state into sentence
 * @param {string} text text status, example: TEAM_FORMING
 * @return {string} A string in the "Team forming" format
 */
export const fromUppercaseToSentenceCase = (text: string): string => {
  return toSentenceCase(text.toLowerCase().replace(/_/g, ' '));
};

export function getCookie(name) {
  const value = `; ${document?.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return '';
}

export function getValidURL(link: string) {
  if (link.indexOf('http://') == 0 || link.indexOf('https://') == 0) {
    return link;
  } else {
    return 'https://' + link;
  }
}
