import Loading from '.';

const LoadingOverlay = ({ text }: { text?: string }) => (
  <div className="absolute w-full h-full min-h-[120px] bg-white/70 z-50 left-0 top-0 rounded-lg flex flex-col justify-center items-center">
    <Loading />
    {text && (
      <div className="text-gray-500 mt-4 text-center font-bold text-2xl bg-white w-full flex justify-center">
        <div className="max-w-sm">{text}</div>
      </div>
    )}
  </div>
);

export default LoadingOverlay;
