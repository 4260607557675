import Avatar, { AvatarProps } from '.';

interface AvatarsProps extends Omit<AvatarProps, 'image'> {
  images: string[];
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  roundness?: 'md' | 'full';
}
// TODO: Don't hardcode the rounded value
const Avatars = ({ images, size = 'md', roundness = 'full' }: AvatarsProps) => (
  <div className="flex flex-row">
    {images.map((image, index) => (
      <div
        className="relative border-white border-2 rounded-2xl"
        key={index}
        style={{ zIndex: images.length - index, left: `${index * -15}px` }}
      >
        <Avatar image={image} size={size} roundness={roundness} />
      </div>
    ))}
  </div>
);

export default Avatars;
