import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

const API_URL = process.env.API_URL;

const httpLink = new HttpLink({ uri: API_URL });

const authMiddleware = (headers: any) =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      fetchOptions: {
        credentials: 'include',
      },
      headers: {
        ...headers,
        cookie: 'qid=s%3AzIJYDdH6keRmtBBNrR_64uf68dhcxKoD.gb6PqWDD5IC%2FsjoMPPmciHJRSmtu2C7Loevj3MQnscE',
      },
    });

    return forward(operation);
  });

const cache = new InMemoryCache();

export const buildClient = (headers: any = {}) => {
  const link = httpLink;

  const ssrMode = typeof window === 'undefined';

  return new ApolloClient({
    ssrMode,
    link: authMiddleware(headers).concat(link),
    cache: ssrMode ? new InMemoryCache({}) : cache,
  });
};
