/* eslint-disable require-jsdoc */
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { Notification } from 'src/shared-components';

const stub = () => {
  throw new Error('You forgot to wrap your component in <NotificationProvider>.');
};

export interface MessageProps {
  title?: string;
  description: string;
}

export interface NotificationContextProps {
  message: MessageProps;
  showing: boolean;
  type: 'success' | 'warning' | 'error';
  showNotification: (type: 'success' | 'warning' | 'error', message: MessageProps) => void;
  hideNotification: () => void;
}

const initialContext: NotificationContextProps = {
  message: { description: '' },
  showing: false,
  type: 'success',
  showNotification: stub,
  hideNotification: stub,
};

const Context = createContext<NotificationContextProps>(initialContext);

export function Provider({ children }: { children: ReactNode | MessageProps }): JSX.Element {
  const [message, setMessage] = useState<MessageProps>({ description: '' });
  const [showing, setShowing] = useState<boolean>(false);
  const [type, setType] = useState<'success' | 'warning' | 'error'>('success');

  const showNotification = useCallback(
    (type: 'success' | 'warning' | 'error', message: MessageProps) => {
      setMessage(message);
      setShowing(true);
      setType(type);
    },
    [setShowing, setType, type],
  );
  const hideNotification = useCallback(() => setShowing(false), [setShowing]);

  return (
    <Context.Provider value={{ showing, type, message, showNotification, hideNotification }}>
      {children}
    </Context.Provider>
  );
}

export default function useNotification() {
  const { showing, message, type, showNotification, hideNotification } = useContext(Context);
  const showError = useCallback((message: MessageProps) => showNotification('error', message), [showNotification]);
  const showWarning = useCallback((message: MessageProps) => showNotification('warning', message), [showNotification]);
  const showSuccess = useCallback((message: MessageProps) => showNotification('success', message), [showNotification]);
  // const showHeadsUp = useCallback((message: MessageProps) => showNotification('headsUp', message), [showNotification]);

  const NotificationBox = ({ showButtonClose }: { showButtonClose: boolean }) => {
    return (
      <>
        {showing && (
          <Notification type={type} showCloseButton={showButtonClose}>
            {message}
          </Notification>
        )}
      </>
    );
  };

  return {
    NotificationBox,
    showing,
    message,
    type,
    showNotification,
    hideNotification,
    // helpers
    showError,
    showWarning,
    showSuccess,
    // showHeadsUp,
  };
}
