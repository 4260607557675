import { FunctionComponent, useEffect, useState } from 'react';

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  size?: 'sm' | 'md' | 'lg';
  color?: 'white' | 'gray' | 'green' | 'yellow' | 'purple' | 'blue';
  roundness?: 'md' | 'full';
}

const Tag: FunctionComponent<TagProps> = ({
  text,
  size = 'lg',
  color = 'white',
  roundness = 'full',
  ...rest
}: TagProps) => {
  const [_size, setSize] = useState<string>();
  const [_color, setColor] = useState<string>('bg-navy text-white');
  const [_roundness, setRoundness] = useState<string>();

  useEffect(() => {
    switch (size) {
      case 'sm':
        setSize('text-sm');
        break;
      case 'md':
        setSize('text-md py-1 px-3 text-sm');
        break;
      case 'lg':
        setSize('text-lg py-2 px-5');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [size]);

  useEffect(() => {
    switch (color) {
      case 'white':
        setColor('bg-white');
        break;
      case 'gray':
        setColor('bg-gray-100 border-gray-100 text-gray-500');
        break;
      case 'green':
        setColor('bg-[#EFF9F1] border-transparent');
        break;
      case 'yellow':
        setColor('bg-[#FFF8E7] border-transparent');
        break;
      case 'purple':
        setColor('bg-[#F1EFFF] text-[#4539B2] border-transparent');
        break;
      case 'blue':
        setColor('bg-[#EEF5FD] border-transparent');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [color]);

  useEffect(() => {
    switch (roundness) {
      case 'full':
        setRoundness('rounded-full');
        break;
      case 'md':
        setRoundness('rounded-md');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [roundness]);

  return (
    <div
      data-testid="tag"
      className={` ${_size} ${_color} ${_roundness} ${rest.className} border w-fit font-medium cursor-default`}
    >
      <span>{text}</span>
    </div>
  );
};

export default Tag;
