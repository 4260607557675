/* istanbul ignore file */
import { User, UserCreateInput, UserUpdateInput } from 'lib/generated/graphql';
import { createContext } from 'react';

const stub = () => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export interface AuthInterface {
  signIn: (input: any) => Promise<any>;
  signOut: () => Promise<void>;
  signUp: (registerData: UserCreateInput) => Promise<void>;
  update: (updateData: UserUpdateInput) => Promise<void>;
  refetch: () => void;
  user: User | null;
  isLoading: boolean;
  isLoggedIn: boolean;
}

const initialContext: AuthInterface = {
  signIn: stub,
  signOut: stub,
  signUp: stub,
  update: stub,
  refetch: stub,
  user: null,
  isLoading: false,
  isLoggedIn: false,
};

const AuthContext = createContext<AuthInterface>(initialContext);

export default AuthContext;
