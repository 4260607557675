import { gql } from '@apollo/client';

export const VIEWER_FRAGMENT = gql`
  fragment Viewer on User {
    id
    name
    email
    photoUrl
    username
    role
    phoneNumber
    calendlyConnected
    calendarLink
    huddleUpSubscriber
    huddleUpSubscription {
      cancelAtPeriodEnd
      id
      periodEnd
      status
      subscriptionId
      type
      userId
    }
    companies {
      id
      slug
      name
      pitch
      stripeCustomerId
      photoUrl
    }
    requestsCustomerOf {
      id
      slug
      name
      status
      partner {
        id
        name
        photoUrl
      }
      roomLink
      customerJoinedRoom
    }
    customerOnboarding {
      state
      viewedLearnModal
      viewedExploreModal
      hadScopingCall
      partnerWelcomeVideo
      completedFirstRequest
      updatedAt
    }
    builderOnboarding {
      state
      stripeAccountId
      hadIntroMeeting
      joinedSlack
      timeAvailable
      minimumRate
      equityAccepted
      verified
      viewedLearnModal
      updatedAt
    }
    builderInviteCode {
      code
      redemptionsLeft
    }
    startupInviteCode {
      code
      redemptionsLeft
    }
    partner {
      name
      username
      email
      bio
      photoUrl
      adminBio
      calendarLink
    }
    invited {
      username
      name
      photoUrl
      role
      huddlesBuilderOf {
        id
      }
      builderOnboarding {
        state
      }
      customerOnboarding {
        state
      }
      requestsCustomerOf {
        id
        slug
        name
        status
        partner {
          id
          name
          photoUrl
        }
        roomLink
        customerJoinedRoom
      }
    }
    isTeamLead
    isPartner
    isVenturePartner
    isCore
    userWorkReferences {
      id
      name
      email
      relation
    }
    socialLinks {
      id
      platformName
      username
      link
    }
    skills {
      id
      value
    }
    interests {
      id
      value
    }
    tags {
      id
      slug
      value
      shown
    }
    companiesFollowed {
      followerId
      followingId
      following {
        id
        slug
        photoUrl
        name
        pitch
      }
    }
    collaborators {
      userId
      collaborator {
        id
        name
        email
        username
        photoUrl
      }
    }
    highlightedSkills {
      id
      value
    }
    tokensReceived {
      id
    }
  }
`;
