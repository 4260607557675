import { useQuery } from '@apollo/client';
import { ArrowSmRightIcon } from '@heroicons/react/outline';
import { useKnockFeed } from '@knocklabs/react-notification-feed';
import {
  AccountBalanceWalletRounded,
  ArrowForward,
  BubbleChart,
  CardGiftcard,
  CardGiftcardRounded,
  Chat,
  CloseRounded,
  CreditCard,
  FlashOnRounded,
  HelpOutline,
  HomeRounded,
  MonetizationOnOutlined,
  PeopleAltOutlined,
  RecentActorsRounded,
  SearchRounded,
  SettingsRounded,
} from '@material-ui/icons';
import { useFlagsmith } from 'flagsmith-react';
import { FindHuddlesQuery, FindHuddlesQueryVariables, RequestStatus, Role, User } from 'lib/generated/graphql';
import { FIND_HUDDLES } from 'lib/queries';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNotification } from 'src/hooks';
import { Divider, Modal } from 'src/shared-components';
import useAuth from 'src/shared-components/Auth';
import Avatar from 'src/shared-components/Avatar';
import Button from 'src/shared-components/Button';

export interface DashboardNavInterface {
  setSideMenuOpen: (open: boolean) => void;
}

const initialContext: DashboardNavInterface = {
  setSideMenuOpen: () => {
    throw new Error('Not Implemented');
  },
};

const DashboardNavContext = createContext<DashboardNavInterface>(initialContext);
export const useDashboardNav = () => useContext<DashboardNavInterface>(DashboardNavContext);

const DEFAULT_USER_AVATAR = process.env.DEFAULT_USER_AVATAR || '';
const DEFAULT_COMPANY_AVATAR = process.env.DEFAULT_COMPANY_AVATAR || '';

const HuddlePartnerBox = () => {
  const { user } = useAuth();
  const modalCalendlyRef = useRef<any>();

  const { data: huddlesData } = useQuery<FindHuddlesQuery, FindHuddlesQueryVariables>(FIND_HUDDLES, {
    variables: {
      where: {
        request: {
          is: {
            customerId: {
              equals: user?.id,
            },
            status: {
              equals: RequestStatus.Approved,
            },
          },
        },
      },
    },
  });

  if (!huddlesData || huddlesData.findHuddles.huddles.length === 0) {
    return null;
  }

  const onClickMessage = () => {
    return huddlesData.findHuddles.huddles[0].request.roomLink
      ? window.open(huddlesData.findHuddles.huddles[0].request.roomLink)
      : window.open(`mailto:${huddlesData.findHuddles.huddles[0].request.partner.email}`);
  };

  return (
    <div className="mt-auto bg-[#F4F4F4] px-5 py-6 rounded-xl flex flex-col gap-y-3 mb-4">
      <div className="w-10">
        <Avatar
          size={'xs'}
          roundness={'md'}
          image={huddlesData.findHuddles.huddles[0].request.partner.photoUrl || DEFAULT_USER_AVATAR}
        />
      </div>
      <h3 className="text-lg font-medium">Your Huddle partner</h3>
      <p className="text-sm text-[#595959]">Here to help you navigate and work through project scope.</p>
      <div className="flex flex-row gap-x-3 mt-2">
        <Button onClick={onClickMessage} size="xs" roundness="lg" color="black">
          Message
        </Button>
        <Button size="xs" roundness="lg" color="white" onClick={() => modalCalendlyRef?.current?.open()}>
          Set up a call
        </Button>
      </div>
      <Modal ref={modalCalendlyRef}>
        <iframe
          data-testid="calendly-iframe"
          src={huddlesData.findHuddles.huddles[0].request.partner.calendarLink!}
          title="description"
          width="400px"
          height="500px"
        ></iframe>
      </Modal>
    </div>
  );
};

const GovernanceSection = () => {
  const { user } = useAuth();
  const router = useRouter();

  return (
    <div>
      <div className={`grid grid-cols-12 items-center h-12 rounded-lg cursor-pointer`}>
        <span className="flex col-span-3 font-medium text-base text-[#595959] text-sm">GOVERNANCE</span>
      </div>
      <div className="flex flex-col gap-y-1">
        {user?.isCore && (
          <NavItem
            title="Users"
            link="/admin"
            active={router.pathname.includes('admin')}
            icon={<PeopleAltOutlined fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            badge={<p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">🏡</p>}
          />
        )}
        {user?.isCore && (
          <NavItem
            title="Payments"
            link="/payments"
            active={router.pathname.includes('payments')}
            icon={<CreditCard fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            badge={<p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">🏡</p>}
          />
        )}
        {(user?.isCore || user?.isPartner) && (
          <NavItem
            title="All Huddles"
            link="/governance/huddles/pending-review"
            active={router.pathname.includes('governance/huddles')}
            icon={<FlashOnRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            badge={
              user.isCore ? (
                <p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">🏡</p>
              ) : (
                <p className="text-[#C66505] bg-[#FFF3E7] rounded-md indent-0 px-2">🤠</p>
              )
            }
          />
        )}
      </div>
    </div>
  );
};

const NavItem = ({
  title,
  icon,
  link,
  arrow,
  badge,
  active = false,
  closeMobileNavBar,
  user,
}: {
  title: any;
  icon: ReactNode;
  link: string;
  badge?: ReactNode;
  arrow?: ReactNode;
  active?: boolean;
  closeMobileNavBar?: () => void;
  user?: User;
}) => {
  const onClick = () => {
    if (closeMobileNavBar) {
      closeMobileNavBar();
    }
  };

  return (
    <Link href={link} passHref>
      <a
        onClick={onClick}
        className={`grid grid-cols-12 px-4 h-12 justify-center items-center rounded-lg ${
          active ? 'bg-[#FFDE9F0]' : ''
        } ${link === '/onboarding' && !user?.builderOnboarding?.verified}`}
      >
        <span className="col-span-2 mr-1">{icon}</span>
        <span className="flex col-span-10 font-medium text-base text-[#595959] items-center">
          {title} <div className="ml-auto">{badge}</div> {arrow}
        </span>
      </a>
    </Link>
  );
};

const NavBar = ({ closeMobileNavBar }: { closeMobileNavBar?: () => void }) => {
  const { user, isLoading } = useAuth();
  const shouldHuddlePartnerDisplay = user?.requestsCustomerOf?.some(
    request => request.status === RequestStatus.ClarificationNeeded || request.status === RequestStatus.Approved,
  );

  const { hasFeature } = useFlagsmith();
  const router = useRouter();
  const knockFeed = useKnockFeed();
  const knockFeedMetadata = knockFeed?.useFeedStore(state => state.metadata);

  const onClick = () => {
    if (closeMobileNavBar) {
      closeMobileNavBar();
    }
  };

  const NotificationsLink = () => (
    <Link href="/notifications">
      <a onClick={onClick} className="relative p-2 rounded-md flex content-center justify-center bg-[#F4F4F4]">
        {knockFeedMetadata?.unread_count > 0 && (
          <div className="absolute bg-[#C1259F] rounded-full p-3 z-10 flex justify-center items-center text-center indent-0 w-5 h-5 -top-1/4 left-3/4">
            <p className="text-white">{knockFeedMetadata?.unread_count}</p>
          </div>
        )}

        <Chat htmlColor="#ABACAC" />
      </a>
    </Link>
  );

  const getBadge = () => {
    if (user?.isCore) {
      return <p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">Core 🏡</p>;
    }
    if (user?.isPartner) {
      return <p className="text-[#C66505] bg-[#FFF3E7] rounded-md indent-0 px-2">Partner 🤠</p>;
    }
    if (user?.isTeamLead) {
      return <p className="text-[#4539B2] bg-[#F1EFFF] rounded-md indent-0 px-2">Lead 🔑</p>;
    }
    if (user?.isVenturePartner) {
      return <p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">Venture Partner 🌱</p>;
    }
    if (user?.role === Role.Builder) {
      if (user?.builderOnboarding?.verified) {
        return <p className="text-[#868686] bg-[#EDEDED] rounded-md indent-0 px-2">Builder 👍</p>;
      } else {
        return <p className="text-[#868686] bg-[#EDEDED] rounded-md indent-0 px-2">Builder</p>;
      }
    }

    return '';
  };

  const getScoutBadge = () => {
    const founderInvited = user?.invited?.filter(invited => invited.role === 'CUSTOMER');
    if (founderInvited?.length >= 5) {
      return (
        <p className="bg-[#E4B976] text-md rounded-md indent-0 px-2  sm:bg-huddlePink text-huddleFontPink ">Scout 🔭</p>
      );
    }
    return '';
  };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between lg:hidden">
          <CloseRounded fontSize="medium" onClick={onClick} />
          <div>
            <NotificationsLink />
          </div>
        </div>
        <div className="flex flex-row ">
          <Link href={`/${user?.username}`}>
            <a className="w-20">
              <Avatar
                size={'xs'}
                roundness={'md'}
                image={user?.photoUrl || DEFAULT_USER_AVATAR}
                username={user?.username}
              />
            </a>
          </Link>

          <div className="hidden lg:block ml-auto">
            <NotificationsLink />
          </div>
        </div>

        <div>
          {isLoading && (
            <>
              <Skeleton count={0.5} />
              <div className="flex gap-x-4 items-center font-medium">
                <Skeleton className="w-full" />
                <Skeleton className="w-full" />
              </div>
            </>
          )}
          {!isLoading && (
            <>
              <p className="font-medium text-xl tracking-tight">{user?.name}</p>
              <div className="flex gap-x-4 items-center font-medium">
                <Link href={`/${user?.username}`}>
                  <a className="text-[#595959]">@{user?.username}</a>
                </Link>
                {getBadge()}
                {getScoutBadge()}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-1 mt-4">
        {user?.role === Role.Builder && (
          <NavItem
            title={`Get set up on Huddle`}
            link="/onboarding"
            icon="👋"
            arrow={<ArrowSmRightIcon className="w-6 text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
            user={user}
          />
        )}
        {user?.role === Role.Builder && hasFeature('explorepage') && (
          <NavItem
            title="Explore"
            link="/explore"
            active={router.pathname.includes('explore')}
            icon={<SearchRounded fontSize="medium" className="text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        )}
        {user?.role === Role.Builder && (
          <NavItem
            title="Community"
            link="/community"
            active={router.pathname.includes('community')}
            icon={<HomeRounded fontSize="medium" className="text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        )}

        {user?.role === Role.Customer && (
          <NavItem
            title={user?.companies[0]?.name}
            link={`/${user?.companies[0]?.slug}`}
            icon={
              <div className="w-8">
                <Avatar
                  size={'xxs'}
                  roundness={'full'}
                  image={user?.companies[0]?.photoUrl || DEFAULT_COMPANY_AVATAR}
                />
              </div>
            }
            closeMobileNavBar={onClick}
          />
        )}
        {user?.role === Role.Builder && (
          <NavItem
            title="My Wallet"
            link="/wallet"
            active={router.pathname.includes('wallet')}
            icon={<AccountBalanceWalletRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        )}

        <NavItem
          title="My Huddles"
          link="/my-huddles"
          active={router.pathname.includes('my-huddles')}
          icon={<RecentActorsRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
          closeMobileNavBar={onClick}
        />

        <NavItem
          title="Invite Codes"
          link="/invite-codes"
          active={router.pathname.includes('invite-codes')}
          icon={<CardGiftcardRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
          closeMobileNavBar={onClick}
        />
        {(user?.isCore || user?.isPartner) && <GovernanceSection />}

        <Divider className="my-2 w-full-ignore-padding-4" />
        {user?.role === Role.Customer && (
          <NavItem
            title={`Guide`}
            link="/onboarding"
            active={router.pathname.includes('onboarding')}
            icon={<HelpOutline fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        )}
        {hasFeature('tokenspage') && (
          <NavItem
            title="Give Tokens"
            link="/tokens"
            active={router.pathname.includes('tokens')}
            icon={<MonetizationOnOutlined fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        )}
        <div className="mb-6">
          <NavItem
            title="Settings"
            link="/account/settings"
            active={router.pathname.includes('account/settings')}
            icon={<SettingsRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
            closeMobileNavBar={onClick}
          />
        </div>
      </div>
      <Link href="/invite-codes">
        <div className="bg-[#EAE4F6] rounded-2xl p-6 mx-auto w-full my-4 cursor-pointer mt-auto">
          <div className="bg-[#493C63] h-10 w-10 rounded-full p-2 mb-8">
            <CardGiftcard className="text-[#EAE4F6]" />
          </div>
          <div className="text-[#493C63] text-[17px] font-medium">
            Invite customers and get
            <br />
            $250 when they pay for a Huddle.
          </div>
          <div className="text-[#493C63] opacity-60 text-[17px] font-medium flex items-center mt-1">
            <span>Share Link</span>
            <ArrowForward className="ml-2" />
          </div>
        </div>
      </Link>
      {user?.role === Role.Customer && shouldHuddlePartnerDisplay && <HuddlePartnerBox />}
      <div className={user?.role === Role.Customer ? 'mt-6' : 'mt-auto'}>
        <Link href="/signout">
          <a onClick={onClick} className="font-medium font-base text-[#A8A8A8]">
            Log Out
          </a>
        </Link>
      </div>
    </>
  );
};

const TopNavBar = ({ closeMobileNavBar }: { closeMobileNavBar?: () => void }) => {
  const { user, isLoading } = useAuth();
  const shouldHuddlePartnerDisplay = user?.requestsCustomerOf?.some(
    request => request.status === RequestStatus.ClarificationNeeded || request.status === RequestStatus.Approved,
  );

  const { hasFeature } = useFlagsmith();
  const router = useRouter();
  const knockFeed = useKnockFeed();
  const knockFeedMetadata = knockFeed?.useFeedStore(state => state.metadata);

  const onClick = () => {
    if (closeMobileNavBar) {
      closeMobileNavBar();
    }
  };

  const NotificationsLink = () => (
    <Link href="/notifications">
      <a onClick={onClick} className="relative p-2 rounded-md flex content-center justify-center bg-[#F4F4F4]">
        {knockFeedMetadata?.unread_count > 0 && (
          <div className="absolute bg-[#C1259F] rounded-full p-3 z-10 flex justify-center items-center text-center indent-0 w-5 h-5 -top-1/4 left-3/4">
            <p className="text-white">{knockFeedMetadata?.unread_count}</p>
          </div>
        )}

        <Chat htmlColor="#ABACAC" />
      </a>
    </Link>
  );

  const SettingsLink = () => (
    <Link href="/account/settings">
      <a onClick={onClick} className="relative p-2 rounded-md flex content-center justify-center bg-[#F4F4F4]">
        <SettingsRounded htmlColor="#ABACAC" />
      </a>
    </Link>
  );

  const getBadge = () => {
    if (user?.isCore) {
      return <p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">Core 🏡</p>;
    }
    if (user?.isPartner) {
      return <p className="text-[#C66505] bg-[#FFF3E7] rounded-md indent-0 px-2">Partner 🤠</p>;
    }
    if (user?.isTeamLead) {
      return <p className="text-[#4539B2] bg-[#F1EFFF] rounded-md indent-0 px-2">Lead 🔑</p>;
    }
    if (user?.isVenturePartner) {
      return <p className="text-[#178C31] bg-[#EFF9F1] rounded-md indent-0 px-2">Venture Partner 🌱</p>;
    }
    if (user?.role === Role.Builder) {
      if (user?.builderOnboarding?.verified) {
        return <p className="text-[#868686] bg-[#EDEDED] rounded-md indent-0 px-2">Builder 👍</p>;
      } else {
        return <p className="text-[#868686] bg-[#EDEDED] rounded-md indent-0 px-2">Builder</p>;
      }
    }

    return '';
  };

  const getScoutBadge = () => {
    const founderInvited = user?.invited?.filter(invited => invited.role === 'CUSTOMER');
    if (founderInvited?.length >= 5) {
      return (
        <p className="bg-[#E4B976] text-md rounded-md indent-0 px-2  sm:bg-huddlePink text-huddleFontPink ">Scout 🔭</p>
      );
    }
    return '';
  };

  return (
    <div className="">
      <div className="flex flex-row  ml-5 mr-10 bg-white items-center py-2 ml-auto space-x-15">
        <div className="flex flex-row w-1/5 items-center ml-5">
          <div className="">
            <Link href={`/${user?.username}`}>
              <a>
                <Avatar
                  isLink
                  image={user?.photoUrl || DEFAULT_USER_AVATAR}
                  roundness="md"
                  size="xs"
                  username={user?.username}
                />
              </a>
            </Link>
          </div>
          <div className="h-7 w-7 relative mt-6 -ml-4 hover:opacity-80">
            <Link href={`/${user?.companies[0]?.slug}`}>
              <a>
                <Avatar
                  isLink
                  image={user?.companies[0]?.photoUrl || DEFAULT_COMPANY_AVATAR}
                  size="xxs"
                  roundness="full"
                />
              </a>
            </Link>
          </div>
          <div className="flex flex-row whitespace-nowrap">
            <NavItem
              title=" Refer a friend"
              link="/invite-codes"
              active={router.pathname.includes('invite-codes')}
              icon={<CardGiftcardRounded fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
              closeMobileNavBar={onClick}
            />
          </div>
        </div>

        <div className="flex flex-row grow px-12">
          <div className="flex justify-center w-1/2 ">
            <NavItem
              title="My Huddles"
              link="/my-huddles"
              active={router.pathname.includes('my-huddles')}
              icon={<BubbleChart fontSize="medium" className="w-8 text-[#A8A8A8]" aria-hidden="true" />}
              closeMobileNavBar={onClick}
            />
          </div>
          <div className="flex justify-center w-1/2">
            <NavItem
              title={`My Wallet`}
              link={`/account/settings/banking`}
              active={router.pathname.includes('explore')}
              icon={<AccountBalanceWalletRounded fontSize="medium" className="text-[#A8A8A8]" aria-hidden="true" />}
              closeMobileNavBar={onClick}
            />
          </div>
          {/* <div className="flex justify-center w-1/3">
            <NavItem
              title="Explore"
              link="/explore"
              active={router.pathname.includes('explore')}
              icon={<SearchRounded fontSize="medium" className="text-[#A8A8A8]" aria-hidden="true" />}
              closeMobileNavBar={onClick}
            />
          </div> */}
        </div>
        <div className="flex flex-row w-1/5 justify-end gap-x-6 items-center ml-auto">
          <div className="flex flex-row">
            <NotificationsLink />
          </div>
          <div className="flex flex-row">
            <SettingsLink />
          </div>
          {getBadge()}
          {getScoutBadge()}
          <div className="flex flex-row">
            <Link href="/signout">
              <a onClick={onClick} className="font-medium font-base text-[#A8A8A8]">
                Log Out
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  const { user, isLoading } = useAuth();
  const router = useRouter();
  const { NotificationBox } = useNotification();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  if (
    router.pathname === '/' ||
    router.pathname.startsWith('/apply') ||
    router.pathname.startsWith('/welcome') ||
    router.pathname.startsWith('/forgot-password') ||
    router.pathname.startsWith('/signin') ||
    router.pathname.startsWith('/join') ||
    router.pathname.startsWith('/request-magic-link') ||
    router.pathname.startsWith('/magic-link') ||
    router.pathname.startsWith('/testing') ||
    router.pathname.startsWith('/invite/') ||
    router.pathname.startsWith('/builder-invite') ||
    (router.pathname.startsWith('/book') && !isLoading && (!user || user?.role === Role.Builder))
  ) {
    return (
      <main data-testid="container" className="min-h-screen font-DMSans">
        {children}
        <NotificationBox showButtonClose />
      </main>
    );
  }

  if (router.pathname.startsWith('/admin/project') && router.pathname !== '/admin/projects') {
    return (
      <main data-testid="container" className="min-h-screen font-DMSans">
        <nav className="grid grid-cols-4 items-center justify-items-center h-16">
          <Link href={'/admin'}>
            <a className="lg:grid grid-cols-4 ml-0 hidden">
              <Image src="/images/logo.svg" width={96} height={28} />
            </a>
          </Link>
          <h1 className="col-span-2 font-bold text-2xl">Proposal Builder</h1>
        </nav>
        {children}
        <NotificationBox showButtonClose />
      </main>
    );
  }

  if (router.pathname.includes('proposal') && !router.pathname.includes('admin')) {
    return <>{children}</>;
  }

  return (
    <>
      <main data-testid="container" className="flex flex-col h-screen font-DMSans relative">
        {/* Conditional rendering for TopNavBar for Customer */}
        {user?.role === Role.Customer && (
          <div className="hidden lg:flex flex-col border bg-white">
            <TopNavBar />
          </div>
        )}

        <div className="flex flex-row h-full">
          {/* Mobile Nav Bar */}
          <div
            className={`absolute bg-white w-full h-screen overflow-scroll inset-0 z-10 p-5 shadow flex flex-col gap-y-6 transition duration-300 ease-in-out ${
              sideMenuOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <NavBar closeMobileNavBar={() => setSideMenuOpen(false)} />
          </div>

          {/* Desktop Nav bar for Builder */}
          {user?.role === Role.Builder && (
            <div className="hidden h-screen lg:flex flex-col border bg-white p-5 min-w-[300px] max-w-[350px] overflow-y-auto">
              <NavBar />
            </div>
          )}
          {user?.role && (
            <div id="scrollable-target" className="bg-[#FAFAFA] overflow-y-auto w-full flex-1">
              <DashboardNavContext.Provider value={{ setSideMenuOpen }}>{children}</DashboardNavContext.Provider>
            </div>
          )}
        </div>
      </main>
      <NotificationBox showButtonClose />
    </>
  );
};

export default Layout;
