import { Goal, Tag as TagProp } from 'lib/generated/graphql';
import { FunctionComponent } from 'react';
import Tag from './Tag';

interface TagsProps extends React.HTMLAttributes<HTMLUListElement> {
  tags: TagProp[] | Goal[];
  size?: 'sm' | 'md' | 'lg';
  color?: 'white' | 'gray';
  roundness?: 'md' | 'full';
}
const Tags: FunctionComponent<TagsProps> = ({ tags, size, color, roundness, ...rest }: TagsProps) => {
  return (
    <ul {...rest}>
      {tags?.map(tag => {
        if (!tag.shown) return null;
        return (
          <li key={tag.slug} className="mr-2 w-fit inline-block mb-2">
            <Tag text={tag.value} size={size} color={color} roundness={roundness} />
          </li>
        );
      })}
    </ul>
  );
};

export default Tags;
