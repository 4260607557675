import { ApolloProvider } from '@apollo/client';
import { KnockFeedProvider } from '@knocklabs/react-notification-feed';
import * as snippet from '@segment/snippet';
import { FlagsmithProvider } from 'flagsmith-react';
import { init } from 'lib/sentry';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import 'react-dates/lib/css/_datepicker.css';
import { Provider as NotificationProvider } from 'src/hooks/useNotification';
import { AuthProvider, RouteGuard } from 'src/shared-components/Auth';
import useAuth from 'src/shared-components/Auth/useAuth';
import DashboardLayout from 'src/shared-components/Layout/DashboardLayout';
import { getCookie } from 'utils/common';
import { buildClient } from '../lib/apollo';
import SEO from '../next-seo.config';
import 'styles/globals.scss';
import 'styles/react_dates_overrides.scss';

init();

// only initialize when in the browser
if (typeof window !== 'undefined') {
  LogRocket.init(process.env.LOGROCKET_ID);
  // plugins should also only be initialized when in the browser
  setupLogRocketReact(LogRocket);
}

/**
 *  Renders the Segment Snippet
 *  @return {Object} Segment Snippet.
 */
function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

if (process.browser) {
  Router.events.on('routeChangeComplete', url => {
    // @ts-ignore
    window.analytics.page(url);
  });
}

const KnockWrapper = ({ Component, pageProps }: any) => {
  const { user } = useAuth();

  if (process.browser && user) {
    return (
      <KnockFeedProvider
        apiKey={process.env.NEXT_PUBLIC_KNOCK_API_KEY}
        feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_ID}
        userId={user ? JSON.stringify(user.id) : null}
        userToken={process.browser ? getCookie('knock_token') : null}
      >
        <DashboardLayout>
          <Component {...pageProps} />
        </DashboardLayout>
      </KnockFeedProvider>
    );
  } else {
    return (
      <DashboardLayout>
        <Component {...pageProps} />
      </DashboardLayout>
    );
  }
};

/**
 *  Retrieves the Main object
 *  @param {any} Component Component.
 * *  @param {any} pageProps pageProps.
 *  @return {Object} Main object.
 */
function Main({ Component, pageProps }: AppProps) {
  const client = buildClient();

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <DefaultSeo {...SEO} />
        <RouteGuard>
          <Head>
            <title>Huddle App</title>
          </Head>
          <NotificationProvider>
            <KnockWrapper Component={Component} pageProps={pageProps} />
          </NotificationProvider>
        </RouteGuard>
      </AuthProvider>
    </ApolloProvider>
  );
}

const MyApp = (props: any) => {
  return (
    <FlagsmithProvider environmentId={process.env.NEXT_PUBLIC_FLAGSMITH_API_KEY as string}>
      <Script id="segment-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
      <Main {...props} />
    </FlagsmithProvider>
  );
};

export default MyApp;
