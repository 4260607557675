export default {
  title: 'Huddle',
  description:
    'Huddle is a group of builders that invest their time in startups they believe in.  Vetted founders post projects and the Huddle community assembles into teams to fractionally work on them for cash and equity. ⚡',
  canonical: 'https://app.huddle.works/',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://app.huddle.works/',
    site_name: 'Huddle',
    images: [
      {
        url: '/images/seo/banner-01.jpg',
        width: 1500,
        height: 500,
      },
    ],
  },
};
