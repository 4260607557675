import { gql } from '@apollo/client';
import { VIEWER_FRAGMENT } from './fragments';

export const SIGNIN_MUTATION = gql`
  ${VIEWER_FRAGMENT}
  mutation Login($input: UserLoginInput!) {
    login(input: $input) {
      ...Viewer
    }
  }
`;

export const SIGN_UP_USER = gql`
  mutation signup($signupInput: UserCreateInput!) {
    signup(input: $signupInput) {
      id
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($updateData: UserUpdateInput!) {
    update(input: $updateData) {
      id
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($createCompanyData: CompanyCreateInput!) {
    createCompany(input: $createCompanyData) {
      id
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompany($updateCompanyData: CompanyUpdateInput!, $updateCompanyWhere: CompanyWhereUniqueInput!) {
    updateCompany(input: $updateCompanyData, where: $updateCompanyWhere) {
      id
      slug
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation UpdateRequest($input: RequestUpdateInput!, $where: RequestWhereUniqueInput!) {
    updateRequest(input: $input, where: $where) {
      id
      status
    }
  }
`;

export const ADMIN_UPDATE_REQUEST = gql`
  mutation AdminUpdateRequest($input: AdminRequestUpdateInput!, $where: RequestWhereUniqueInput!) {
    adminUpdateRequest(input: $input, where: $where) {
      id
      status
    }
  }
`;

export const ADMIN_UPDATE_USER_MUTATION = gql`
  mutation AdminUpdateUser($adminUpdateUserInput: AdminUserUpdateInput!, $adminUpdateUserUserId: Int!) {
    adminUpdateUser(input: $adminUpdateUserInput, userId: $adminUpdateUserUserId) {
      id
      builderOnboarding {
        state
      }
      customerOnboarding {
        state
      }
    }
  }
`;

export const REDEEM_INVITE_CODE = gql`
  mutation RedeemInviteCodeMutation($redeemInviteCodeInput: String!) {
    redeemInviteCode(input: $redeemInviteCodeInput)
  }
`;

export const UPDATE_INVITE_CODE_REDEMPTIONS = gql`
  mutation UpdateInviteCodeRedemptions($id: Float!, $amount: Float!) {
    updateInviteCodeRedemptions(id: $id, amount: $amount)
  }
`;

export const UPDATE_PROPOSAL = gql`
  mutation UpdateProposal($where: ProposalWhereUniqueInput!, $input: ProposalUpdateInput!) {
    updateProposal(where: $where, input: $input) {
      id
    }
  }
`;

export const UPDATE_HUDDLE = gql`
  mutation UpdateHuddle($where: HuddleWhereUniqueInput!, $input: HuddleUpdateInput!) {
    updateHuddle(where: $where, input: $input) {
      id
    }
  }
`;

export const CREATE_REACTION_MUTATION = gql`
  mutation CreateReaction($input: PostWhereUniqueInput!) {
    createReaction(input: $input) {
      id
      postId
      userId
    }
  }
`;

export const DELETE_REACTION_MUTATION = gql`
  mutation DeleteReaction($input: PostWhereUniqueInput!) {
    deleteReaction(input: $input)
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: String!) {
    changePassword(input: $input)
  }
`;

export const REQUEST_MAGIC_LINK_MUTATION = gql`
  mutation RequestMagicLink($input: String!) {
    magicLink(input: $input)
  }
`;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      email
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      email
    }
  }
`;

export const CREATE_OPT_IN_MUTATION = gql`
  mutation CreateOptIn($input: OptInCreateInput!) {
    createOptIn(input: $input) {
      id
    }
  }
`;

export const ADMIN_CREATE_OPT_IN_MUTATION = gql`
  mutation AdminCreateOptIn($input: AdminOptInCreateInput!) {
    adminCreateOptIn(input: $input) {
      id
    }
  }
`;

export const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($input: FollowUserInput!) {
    followUser(input: $input)
  }
`;

export const UNFOLLOW_USER_MUTATION = gql`
  mutation UnFollowUser($input: FollowUserInput!) {
    unfollowUser(input: $input)
  }
`;

export const FOLLOW_COMPANY_MUTATION = gql`
  mutation FollowCompany($input: FollowCompanyInput!) {
    followCompany(input: $input)
  }
`;

export const UNFOLLOW_COMPANY_MUTATION = gql`
  mutation UnFollowCompany($input: FollowCompanyInput!) {
    unfollowCompany(input: $input)
  }
`;

export const MARK_NOTIFICATION_READ_MUTATION = gql`
  mutation MarkNotificationsAsRead($input: NotificationWhereUniqueInput!) {
    markNotificationsAsRead(input: $input)
  }
`;

export const CREATE_WORK_REFERENCE = gql`
  mutation CreateWorkReference($data: WorkReferenceCreateWithoutUserInput!) {
    createWorkReference(data: $data) {
      id
    }
  }
`;

export const EMAIL_WORK_REFERENCE = gql`
  mutation EmailWorkReference($data: WorkReferenceCreateWithoutUserInput!) {
    emailWorkReference(data: $data)
  }
`;

export const UPDATE_WORK_REFERENCE_MUTATION = gql`
  mutation UpdateWorkReference($where: WorkReferenceWhereUniqueInput!, $input: WorkReferenceUpdateInput!) {
    updateWorkReference(where: $where, data: $input) {
      id
    }
  }
`;

export const SET_COLLABORATORS_MUTATION = gql`
  mutation SET_COLLABORATORS_MUTATION($data: [CollaboratorCreateManyInput!]!, $userId: Int!) {
    setCollaborators(data: $data, userId: $userId) {
      count
    }
  }
`;

export const SEND_COLLABORATOR_EMAILS = gql`
  mutation SendCollaboratorEmails($emails: [String!]!, $userId: Int!) {
    sendCollaboratorEmails(emails: $emails, userId: $userId)
  }
`;

export const UPDATE_OPTIN_MUTATION = gql`
  mutation UpdateOptIn($input: OptInUpdateInput!, $where: OptInWhereUniqueInput!) {
    updateOptIn(where: $where, input: $input) {
      id
    }
  }
`;

export const ADMIN_UPDATE_OPTIN_MUTATION = gql`
  mutation AdminUpdateOptIn($input: OptInUpdateInput!, $where: OptInWhereUniqueInput!) {
    adminUpdateOptIn(where: $where, input: $input) {
      id
    }
  }
`;

export const NEW_REQUEST_MUTATION = gql`
  mutation CreateRequest($input: RequestCreateInput!) {
    createRequest(input: $input) {
      id
      brief
      equityOrCrypto
      cashBudget
      preferredDuration
      preferredStartDate
      createdAt
      updatedAt
    }
  }
`;

export const NEW_SCOPED_REQUEST_MUTATION = gql`
    mutation CreateScopedRequest($input: ScopedRequestCreateInput!) {
        createScopedRequest(input: $input) {
            id
            brief
            equityOrCrypto
            cashBudget
            preferredDuration
            preferredStartDate
            createdAt
            updatedAt
        }
    }
`;

export const SET_PARTNER = gql`
  mutation SetRequestHuddlePartner($huddlePartner: UserWhereUniqueInput!, $request: RequestWhereUniqueInput!) {
    setRequestHuddlePartner(huddlePartner: $huddlePartner, request: $request) {
      id
      name
    }
  }
`;

export const APPROVE_BUILDERS_MUTATION = gql`
  mutation ApproveBuildersScript {
    approveBuildersScript
  }
`;

export const CREATE_TOKEN = gql`
  mutation CreateToken($data: TokenCreateInput!) {
    createToken(data: $data) {
      id
    }
  }
`;

export const CREATE_PAY_INTERVAL = gql`
  mutation CreatePayInterval($input: Float!) {
    createPayInterval(input: $input) {
      id
      payouts {
        id
        builderId
        totalAmount
        createdAt
        updatedAt
        hours
        hourlyRate
        paid
      }
      payments {
        id
        customerId
        totalAmount
        platformFee
        createdAt
        updatedAt
        paid
        invoiceSent
      }
    }
  }
`;

export const REMOVE_PAY_INTERVAL = gql`
  mutation RemovePayInterval($input: Float!) {
    removePayInterval(input: $input)
  }
`;

export const UPDATE_PAY_INTERVAL = gql`
  mutation UpdatePayInterval($data: PayIntervalUpdateInput!, $where: PayIntervalWhereUniqueInput!) {
    updatePayInterval(data: $data, where: $where) {
      id
    }
  }
`;

export const PAY_BUILDERS_IN_INTERVAL = gql`
  mutation PayBuildersInInterval($input: Float!) {
    payBuildersInInterval(input: $input)
  }
`;

export const SEND_UPDATED_PROPOSAL = gql`
  mutation SendUpdatedProposal($where: ProposalWhereUniqueInput!) {
    sendUpdatedProposal(where: $where)
  }
`;

export const SEND_INVITE_EMAILS = gql`
  mutation SendInviteEmails($emails: [String!]!, $referralType: String!) {
    sendInviteEmails(emails: $emails, referralType: $referralType)
  }
`;

export const CREATE_HUDDLE_UP = gql`
  mutation CreateHuddleUp($type: HuddleUpSubscriptionType!, $stripeId: String!, $userId: Int!) {
    createHuddleUp(type: $type, stripeId: $stripeId, userId: $userId) {
      clientSecret
      id
    }
  }
`;

export const CANCEL_HUDDLE_UP = gql`
  mutation CancelHuddleUp($where: HuddleUpWhereUniqueInput!) {
    cancelHuddleUp(where: $where)
  }
`;

export const UPDATE_HUDDLE_UP = gql`
  mutation UpdateHuddleUp($data: HuddleUpUpdateInput!, $where: HuddleUpWhereUniqueInput!) {
    updateHuddleUp(data: $data, where: $where) {
      cancelAtPeriodEnd
      id
      periodEnd
      status
      type
      userId
      subscriptionId
    }
  }
`;

export const UPDATE_FEES_HUDDLEUP_MEMBER = gql`
  mutation UpdateProposalFees($customerId: Int!) {
    updateProposalFees(customerId: $customerId)
  }
`;

export const LEAD_PAYMENT = gql`
  mutation LeadPayment($leadId: Int!) {
    leadPayment(leadId: $leadId) {
      id
      clientSecret
    }
  }
`;

export const CREATE_LEAD_BOOKING = gql`
  mutation CreateLeadBooking($data: CreateLeadBooking!) {
    createLeadBooking(data: $data) {
      builderId
      customerId
      date
      hourlyRate
      id
      paid
      paymentId
    }
  }
`;

export const UPDATE_LEAD_BOOKING = gql`
  mutation updateLeadBooking($data: LeadBookingUpdateInput!, $where: LeadBookingWhereUniqueInput!) {
    updateLeadBooking(data: $data, where: $where) {
      builderId
      customerId
      date
      hourlyRate
      id
      paid
      paymentId
    }
  }
`;

export const PAYOUT_BUILDERS = gql`
  mutation PayoutBuilders($payoutIds: [Int!]!) {
    payoutBuilders(payoutIds: $payoutIds) {
      id
      stripeTransferIds
    }
  }
`;
