import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';

interface NotificationProps {
  type: 'success' | 'warning' | 'error';
  children: any; // ReactNode | MessageProps;
  position?: 'bottom-right' | 'top-right';
  showCloseButton?: boolean;
}

const Notification = ({
  type = 'success',
  children,
  position = 'bottom-right',
  showCloseButton = true,
}: NotificationProps) => {
  const [show, setShow] = useState(true);
  const [color, setColor] = useState('green');
  const [icon, setIcon] = useState<any>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    switch (type) {
      case 'success':
        setColor('green');
        setIcon(<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />);
        break;
      case 'warning':
        setColor('yellow');
        setIcon(<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />);
        break;
      case 'error':
        setColor('red');
        setIcon(<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed z-30 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div
          className={`w-full ${
            position === 'bottom-right' ? 'mt-auto' : ''
          } flex flex-col items-center space-y-4 sm:items-end`}
        >
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`max-w-sm w-full bg-${color}-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {children?.title && <p className={`text-sm font-medium text-${color}-800`}>{children.title}</p>}
                    <p className={`mt-1 text-sm text-${color}-700`}>
                      {children.description ? children.description : children}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    {showCloseButton && (
                      <button
                        className={`bg-transparent rounded-md inline-flex text-${color}-700 hover:text-${color}-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* HACK: colors are not loading/changing */}
              <div className="bg-red-100 text-red-700 hover:text-red-500 text-red-800 bg-green-100 text-green-700 hover:text-green-500 text-green-800 bg-yellow-100 text-yellow-700 hover:text-yellow-500 text-yellow-800" />
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notification;
