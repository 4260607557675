import { Link } from '@material-ui/icons';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from 'src/shared-components/Button';
import wait from 'waait';

const ButtonCopy = ({
  text,
  textToCopy,
  textClassName,
  variant = 'light',
  fullWidthButton = false,
  noButton = false,
}: {
  text: string;
  textToCopy?: string;
  textClassName?: string;
  variant?: 'light' | 'dark';
  fullWidthButton?: boolean;
  noButton?: boolean;
}) => {
  const [tooltip, setTooltip] = useState({
    content: 'Copy to clipboard',
    isOpen: false,
  });

  const handleOnOpenChange = newOpenState => {
    setTooltip(t => ({ ...t, isOpen: newOpenState }));
  };

  return (
    <div data-testid="button-copy">
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root open={tooltip.isOpen} onOpenChange={handleOnOpenChange}>
          <Tooltip.Trigger asChild>
            <span tabIndex={0}>
              <CopyToClipboard
                text={textToCopy ? textToCopy : text}
                onCopy={async () => {
                  setTooltip({
                    content: 'Copied!',
                    isOpen: true,
                  });

                  await wait(1000);

                  setTooltip(t => ({
                    ...t,
                    content: 'Copy to clipboard',
                  }));
                }}
              >
                {!noButton ? (
                  <Button
                    color={variant === 'dark' ? 'black' : 'white'}
                    size="lg"
                    roundness="xl"
                    fullWidth={fullWidthButton}
                  >
                    <Link className="-rotate-45 mr-2" />
                    <span className={textClassName}>{text}</span>
                  </Button>
                ) : (
                  <Link className="-rotate-45 mr-2" fontSize="small" />
                )}
              </CopyToClipboard>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade bg-gray-600 text-white select-none rounded-[4px] px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
              sideOffset={24}
            >
              {tooltip.content}
              <Tooltip.Arrow className="fill-gray-600" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};

export default ButtonCopy;
