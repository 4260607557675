/* istanbul ignore file */
import { omit } from 'lodash';

const EditButton = ({ ...rest }) => (
  <span
    className={`w-[20px] h-[20px] ml-3 cursor-pointer bg-pencilIcon hover:bg-pencilIconActive bg-cover ${rest.className}`}
    {...omit(rest, ['className'])}
  >
    <div className="w-[20px] h-[20px]"></div>
  </span>
);

export default EditButton;
