/* eslint-disable require-jsdoc */
import { BuilderOnboardingState, Company, CustomerOnboardingState, Request, Role } from 'lib/generated/graphql';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useAuth from 'src/shared-components/Auth/useAuth';

const publicPaths = [
  '/',
  '/signin',
  '/signout',
  '/apply/founder',
  '/apply/builder',
  '/apply/creator',
  '/terms',
  '/privacy-policy',
  '/forgot-password',
  '/join/builder',
  '/join/creator',
  '/join/founder',
  '/join/hang-tight',
  '/magic-link',
  '/welcome',
  '/request-magic-link',
  '/book',
  '/testing',
];
const acceptedBuilderStates = [
  BuilderOnboardingState.Imported,
  BuilderOnboardingState.Username,
  BuilderOnboardingState.UploadProfilePic,
  BuilderOnboardingState.Complete,
];
const acceptedFounderStates = [
  CustomerOnboardingState.Imported,
  CustomerOnboardingState.CompanyProfile,
  CustomerOnboardingState.FirstProject,
  CustomerOnboardingState.Complete,
];

const RouteGuard = ({ children }: { children: any }) => {
  const { isLoggedIn, isLoading, user } = useAuth();
  const router = useRouter();
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      // on initial load - run auth check
      authCheck(router.asPath);

      // on route change start - hide page content by setting authorized to false
      // const hideContent = () => setAuthorized(false);
      // router.events.on('routeChangeStart', hideContent);

      // on route change complete - run auth check
      router.events.on('routeChangeComplete', authCheck);

      // unsubscribe from events in useEffect return function
      return () => {
        // router.events.off('routeChangeStart', hideContent);
        router.events.off('routeChangeComplete', authCheck);
      };
    } else {
      return undefined;
    }
  }, [isLoading, user]);

  const founderAuthCheck = (path: string) => {
    if (!acceptedFounderStates.includes(user!.customerOnboarding!.state)) {
      if (user!.customerOnboarding!.state === CustomerOnboardingState.InviteCodeOrWaitlist) {
        router.push({
          pathname: '/join/hang-tight',
          query: { role: 'founder' },
        });
      } else {
        router.push({
          pathname: '/my-huddles',
        });
      }
    } else if (acceptedFounderStates.includes(user!.customerOnboarding!.state) && path === '/') {
      router.push({
        pathname: `/${user!.username}`,
      });
    } else {
      // TODO: Come up with a replacement for this that still allows founders to see builders profiles
      const acceptedFounderPaths = [
        `/${user!.username}`,
        '/account',
        '/my-projects',
        '/onboarding',
        '/my-huddles',
        '/notifications',
        '/invite-codes',
        '/explore',
      ];
      user!.companies?.forEach((company: Company) => {
        acceptedFounderPaths.push(`/${company.slug}`);
      });
      user!.requestsCustomerOf?.forEach((request: Request) => {
        acceptedFounderPaths.push(`huddles/${request.slug}`);
      });
      const matches = acceptedFounderPaths.filter(s => path.includes(s));
      if (!matches.length) {
        if (user?.companies?.length) {
          router.push({
            pathname: `/${user!.companies![0].slug}`,
          });
        } else {
          router.push({
            pathname: `/${user!.username}`,
          });
        }
      }
    }
  };
  const builderAuthCheck = path => {
    const unApprovedStates = [
      BuilderOnboardingState.InviteCodeOrWaitlist,
      BuilderOnboardingState.Tags,
      BuilderOnboardingState.InviteCodeUsed,
      CustomerOnboardingState.Approved,
    ];

    if (!acceptedBuilderStates.includes(user!.builderOnboarding!.state)) {
      if (unApprovedStates.includes(user!.builderOnboarding!.state)) {
        router.push({
          pathname: '/join/hang-tight',
          query: { role: 'creator' },
        });
      } else {
        router.push({
          pathname: '/join/builder',
        });
      }
    } else if (acceptedBuilderStates.includes(user!.builderOnboarding!.state) && path === '/') {
      router.push({
        pathname: '/community',
      });
    } else if (path.startsWith('/governance')) {
      if (!user.isCore) {
        router.push({
          pathname: '/community',
        });
      }
    }
  };

  function authCheck(url: string) {
    // redirect to signin page if accessing a private page and not logged in

    const path = url.split('?')[0];
    if (
      !isLoggedIn &&
      !publicPaths.includes(path) &&
      !path.includes('/proposal') &&
      !path.startsWith('/invite/') &&
      !path.startsWith('/builder-invite') &&
      !path.startsWith('/book')
    ) {
      router.push('/signin');
    } else if (path === '/book') {
      router.push('/my-huddles');
    } else if (path.startsWith('/book/')) {
      return;
    } else if (isLoggedIn && !publicPaths.includes(path)) {
      setAuthorized(true);
      if (user!.role === Role.Builder) {
        builderAuthCheck(path);
      } else if (user!.role === Role.Customer) {
        founderAuthCheck(path);
      }
    } else if (!isLoggedIn && path === '/') {
      router.push('/signin');
    } else if (isLoggedIn && path === '/') {
      setAuthorized(true);
      if (user!.role === Role.Builder) {
        builderAuthCheck(path);
      } else if (user!.role === Role.Customer) {
        founderAuthCheck(path);
      }
    } else if (isLoggedIn) {
      setAuthorized(true);
    }
  }

  return authorized && children;
};

export default RouteGuard;
