import { gql } from '@apollo/client';
import { VIEWER_FRAGMENT } from './fragments';

export const GET_VIEWER = gql`
  ${VIEWER_FRAGMENT}
  query getViewer {
    me {
      ...Viewer
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GET_USER_QUERY($getUserData: UserWhereUniqueInput!) {
    getUser(data: $getUserData) {
      id
      name
      username
      photoUrl
      bio
      role
      doesUserFollow
      isTeamLead
      calendlyConnected
      tags {
        id
        slug
        value
        shown
      }
      socialLinks {
        id
        platformName
        link
      }
      inviter {
        username
        name
        photoUrl
      }
      builderOnboarding {
        state
      }
      skills {
        id
        value
      }
      interests {
        id
        value
      }
      collaborators {
        userId
        collaborator {
          id
          name
          email
          username
          photoUrl
        }
      }
      companiesFollowed {
        followerId
        followingId
        following {
          id
          slug
          photoUrl
          name
          pitch
        }
      }
      highlightedSkills {
        id
        value
      }
    }
  }
`;

export const GET_LEAD_QUERY = gql`
  query GET_LEAD_QUERY($getUserData: UserWhereUniqueInput!) {
    getLead(data: $getUserData) {
      id
      isTeamLead
      name
      username
      photoUrl
      bio
      role
      calendarLink
      calendlyConnected
      skills {
        id
        value
      }
      interests {
        id
        value
      }
      tags {
        id
        slug
        value
        shown
      }
      builderOnboarding {
        minimumRate
      }
    }
  }
`;

export const GET_SELECTED_LEADS = gql`
  query GetSelectedLeads($where: UserWhereInput) {
    getSelectedLeads(where: $where) {
      id
      name
      username
      photoUrl
      tags {
        id
        value
      }
      builderOnboarding {
        minimumRate
      }
      bio
      interests {
        id
        value
      }
      calendlyConnected
      products
    }
  }
`;

export const GET_INVITER = gql`
  query GetInviter($getUserData: UserWhereUniqueInput!) {
    getInviter(data: $getUserData) {
      name
      username
      photoUrl
      builderInviteCode {
        code
        redemptionsLeft
      }
      startupInviteCode {
        code
      }
    }
  }
`;

export const ADMIN_GET_USERS = gql`
  query ADMIN_GET_USERS(
    $adminGetUsersCursor: UserWhereUniqueInput
    $adminGetUsersWhere: UserWhereInput
    $adminGetUsersTake: Int
    $adminGetUsersOrderBy: [UserOrderByWithRelationInput!]
  ) {
    adminGetUsers(
      cursor: $adminGetUsersCursor
      where: $adminGetUsersWhere
      take: $adminGetUsersTake
      orderBy: $adminGetUsersOrderBy
    ) {
      users {
        id
        name
        username
        photoUrl
        products
        bio
        email
        phoneNumber
        isTeamLead
        isVenturePartner
        isCore
        createdAt
        calendarLink
        startupInviteCodeId
        builderInviteCodeId
        builderInviteCode {
          code
          redemptionsLeft
        }
        startupInviteCode {
          code
          redemptionsLeft
        }
        userWorkReferences {
          id
          verified
        }
        inviter {
          id
          name
        }
        tags {
          slug
          value
        }
        skills {
          id
          value
        }
        interests {
          id
          value
        }
        socialLinks {
          platformName
          link
          username
        }
        builderOnboarding {
          state
          joinedSlack
          hadIntroMeeting
          viewedLearnModal
          stripeAccountId
          minimumRate
          verified
          yearsOfExperience
          location
          verified
          stripeAccountId
          joinedSlack
          hadIntroMeeting
          viewedLearnModal
        }
        companiesFollowed {
          followerId
          followingId
          following {
            id
            slug
            photoUrl
          }
        }
      }
      count
    }
  }
`;

export const ADMIN_GET_COMPANIES = gql`
  query ADMIN_GET_COMPANIES(
    $adminGetCompaniesWhere: CompanyWhereInput
    $adminGetCompaniesCursor: CompanyWhereUniqueInput
    $adminGetCompaniesTake: Int
    $adminGetCompaniesOrderBy: [CompanyOrderByWithRelationInput!]
  ) {
    adminGetCompanies(
      where: $adminGetCompaniesWhere
      cursor: $adminGetCompaniesCursor
      take: $adminGetCompaniesTake
      orderBy: $adminGetCompaniesOrderBy
    ) {
      companies {
        photoUrl
        id
        name
        pitch
        fundingStage
        videoUrl
        slug
        pitchDeckUrl
        createdAt
        goals {
          value
        }
        tags {
          slug
          value
        }
        socialLinks {
          platformName
          link
          username
        }
        customer {
          id
          name
          bio
          email
          phoneNumber
          photoUrl
          username
          customerOnboarding {
            state
            partnerWelcomeVideo
          }
          socialLinks {
            platformName
            link
            username
          }
          partner {
            id
            username
            name
          }
          posts {
            id
          }
          requestsCustomerOf {
            huddle {
              status
              id
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query GET_COMPANY_QUERY($getCompanyData: CompanyWhereUniqueInput!) {
    getCompany(data: $getCompanyData) {
      id
      slug
      name
      pitch
      photoUrl
      videoUrl
      pitchDeckUrl
      raisingMoney
      lookingForAdvisors
      tags {
        id
        value
        slug
        shown
      }
      socialLinks {
        id
        platformName
        link
      }
      customerId
      customer {
        name
        username
        photoUrl
        bio
        calendarLink
        inviter {
          name
          username
          photoUrl
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags($where: TagWhereInput) {
    findTags(where: $where) {
      id
      value
      slug
      createdAt
      updatedAt
      shown
    }
  }
`;

export const GET_GOALS = gql`
  query getGoals($where: GoalWhereInput) {
    findGoals(where: $where) {
      id
      value
      shown
    }
  }
`;

export const ADMIN_FIND_REQUESTS = gql`
  query adminFindRequests(
    $where: RequestWhereInput
    $orderBy: [RequestOrderByWithRelationInput!]
    $cursor: RequestWhereUniqueInput
    $take: Int
  ) {
    findRequests(where: $where, orderBy: $orderBy, cursor: $cursor, take: $take) {
      requests {
        id
        name
        slug
        brief
        status
        preferredStartDate
        preferredDuration
        cashBudget
        equityOrCrypto
        roomLink
        partnerNotes
        partnerId
        partner {
          name
          username
          photoUrl
        }
        createdAt
        goals {
          id
          slug
          value
          shown
        }
        customerId
        company {
          name
          photoUrl
          videoUrl
          slug
          pitch
          industry
          tags {
            id
            slug
            value
            shown
          }
          customer {
            username
            name
            bio
            photoUrl
            inviterId
            inviter {
              username
              photoUrl
            }
            partner {
              username
              name
              photoUrl
            }
          }
        }
        huddle {
          id
          status
          hasUserOptedin
          hasUserReposted
          officialStartDate
          paymentScheduleType
          expectedDurationInterval
          expectedDurationAmount
          teamLead {
            username
            photoUrl
            name
            bio
          }
          proposal {
            id
            status
          }
          builders {
            user {
              username
              name
              photoUrl
              role
            }
            isTeamLead
            hourlyRate
            weeklyCommitment
            weeksCommitted
            equityHourlyRate
            equityWeeklyCommitment
            equityWeeksCommitted
          }
          originalPost {
            id
            _count {
              reposts
              reactions
            }
          }
          _count {
            builders
            optIns
            posts
          }
        }
        _count {
          goals
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const ADMIN_REQUEST_DETAIL_QUERY = gql`
  query ADMIN_REQUEST_DETAIL($where: RequestWhereUniqueInput!, $optInsWhere: OptInWhereInput!) {
    adminFindOptIns(where: $optInsWhere) {
      id
    }
    getRequest(where: $where) {
      id
      brief
      preferredStartDate
      preferredDuration
      equityOrCrypto

      goals {
        value
      }
      company {
        name
      }
      huddle {
        teamLead {
          username
          photoUrl
          name
          bio
        }
        proposal {
          id
          selectedOptIns {
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const ADMIN_FIND_OPT_INS_QUERY = gql`
  query ADMIN_FIND_OPT_INS($where: OptInWhereInput!) {
    adminFindOptIns(where: $where) {
      id
      start
      commitment
      hourlyRate
      equityAccepted
      note
      proposalId
      huddleId
      createdAt
      user {
        id
        username
        name
        photoUrl
        bio
        isTeamLead
        tags {
          slug
          value
        }
      }
      goals {
        slug
        value
      }
    }
  }
`;

export const ADMIN_GET_PROPOSAL_QUERY = gql`
  query ADMIN_GET_PROPOSAL($where: ProposalWhereUniqueInput!, $requestWhere: RequestWhereUniqueInput!) {
    getProposal(where: $where) {
      id
      activeProposalExists
      teamNote
      contractNote
      scopeDeliverablesNote
      goalsNote
      planNote
      miscNotes
      totalCost
      serviceFee
      huddleMarkup
      equityPercentage
      sharedDocumentLink
      status
      totalCostPresentedMonthly
      customerWillingToPayWithEquity
      availableStartDates
      huddleId
      isEquityEnabled
      equityMultiplier
      getActivePayIntervalForProposal {
        id
      }
      selectedOptIns {
        id
        start
        commitment
        hourlyRate
        equityAccepted
        note
        noteShownOnProposal
        proposalId
        proposal_hourlyRate
        proposal_weeklyCommitment
        proposal_weeksToCommit
        proposal_teamLead
        proposal_order
        proposal_title
        proposal_bio
        proposal_portfolioLink
        proposal_linkedInLink
        goals {
          value
        }
        createdAt
        user {
          id
          username
          isTeamLead
          name
          photoUrl
          bio
          tags {
            slug
            value
          }
          builderOnboarding {
            verified
          }
          socialLinks {
            platformName
            link
          }
          createdAt
        }
      }
      paySchedule {
        id
        payIntervals {
          id
          startDate
          endDate
          paidInFull
          payments {
            id
            customerId
            totalAmount
            platformFee
            createdAt
            updatedAt
            invoiceSent
            invoiceSentDate
            paid
            invoicePaidDate
            invoiceUrl
          }
          payouts {
            id
            builderId
            totalAmount
            createdAt
            updatedAt
            hours
            hourlyRate
            paid
            paidDate
            firstPayoutComplete
          }
        }
      }
    }
    getRequest(where: $requestWhere) {
      id
      company {
        name
      }
      huddle {
        teamLead {
          username
          photoUrl
          name
          bio
        }
        officialStartDate
        reservedStartDate
      }
    }
  }
`;

export const GET_REQUEST_QUERY = gql`
  query GetRequest($where: RequestWhereUniqueInput!) {
    getRequest(where: $where) {
      id
      name
      slug
      brief
      status
      preferredStartDate
      preferredDuration
      cashBudget
      equityOrCrypto
      goals {
        id
        slug
        value
        shown
      }
      customerId
      company {
        name
        photoUrl
        pitch
        slug
        tags {
          id
          slug
          value
          shown
        }
        customer {
          username
          name
          photoUrl
          partner {
            username
            name
            photoUrl
          }
        }
      }
      huddle {
        id
        teamLead {
          username
          photoUrl
          name
          bio
        }
        hasUserReposted
        hasUserOptedin
        countHuddleReposts
        proposal {
          id
          status
        }
        builders {
          user {
            username
            name
            photoUrl
            role
          }
        }
        originalPost {
          id
          _count {
            reposts
            reactions
          }
        }
        _count {
          builders
          optIns
          posts
        }
      }
      _count {
        goals
      }
    }
  }
`;

export const FIND_REQUESTS_QUERY = gql`
  query FindRequests($where: RequestWhereInput) {
    findRequests(where: $where) {
      requests {
        id
        name
        slug
        brief
        status
        preferredStartDate
        preferredDuration
        cashBudget
        customerJoinedRoom
        equityOrCrypto
        partnerNotes
        partner {
          username
          photoUrl
        }
        createdAt
        goals {
          id
          slug
          value
          shown
        }
        customerId
        company {
          name
          photoUrl
          videoUrl
          slug
          pitch
          industry
          tags {
            id
            slug
            value
            shown
          }
          customer {
            username
            name
            photoUrl
            partner {
              username
              name
              photoUrl
            }
          }
        }
        huddle {
          hasUserOptedin
          hasUserReposted
          teamLead {
            username
            photoUrl
            name
            bio
          }
          proposal {
            id
            status
          }
          builders {
            user {
              username
              name
              photoUrl
              role
            }
          }
          originalPost {
            id
            _count {
              reposts
              reactions
            }
          }
          _count {
            builders
            optIns
            posts
          }
        }
        _count {
          goals
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROPOSAL_QUERY = gql`
  query GET_PROPOSAL($where: ProposalWhereUniqueInput!, $requestWhere: RequestWhereUniqueInput!) {
    getProposal(where: $where) {
      id
      teamNote
      contractNote
      scopeDeliverablesNote
      proposalSentDate
      expirationDate
      goalsNote
      planNote
      miscNotes
      totalCost
      sharedDocumentLink
      serviceFee
      equityPercentage
      equityMultiplier
      isEquityEnabled
      huddleMarkup
      status
      totalCostPresentedMonthly
      customerWillingToPayWithEquity
      readOnlyAuthToken
      availableStartDates
      activeInvoiceHostedUrl
      getActivePayIntervalForProposal {
        id
        startDate
        endDate
        payments {
          id
          customerId
          totalAmount
          platformFee
          createdAt
          updatedAt
          paid
          invoiceSent
        }
        payouts {
          id
          builderId
          builder {
            name
            photoUrl
          }
          totalAmount
          createdAt
          updatedAt
          hours
          hourlyRate
          paid
        }
      }
      selectedOptIns {
        id
        start
        commitment
        hourlyRate
        equityAccepted
        note
        noteShownOnProposal
        proposal_hourlyRate
        proposal_weeklyCommitment
        proposal_weeksToCommit
        proposal_teamLead
        proposal_order
        proposal_title
        proposal_bio
        proposal_portfolioLink
        proposal_linkedInLink
        proposal_userAccepted
        createdAt
        user {
          id
          username
          isTeamLead
          name
          photoUrl
          bio
          tags {
            shown
            slug
            value
          }
          builderOnboarding {
            verified
          }
          socialLinks {
            platformName
            link
          }
          createdAt
        }
      }
      paySchedule {
        id
        payIntervals {
          id
          startDate
          endDate
          payments {
            id
            customerId
            totalAmount
            platformFee
            createdAt
            updatedAt
            paid
            invoiceSent
          }
          payouts {
            id
            builderId
            totalAmount
            createdAt
            updatedAt
            hours
            hourlyRate
            paid
          }
        }
      }
    }
    getRequest(where: $requestWhere) {
      id
      name
      company {
        id
        name
      }
      goals {
        value
        shown
      }
      preferredStartDate
      preferredDuration
      brief
      equityOrCrypto
      huddle {
        id
        reservedStartDate
        teamLead {
          username
          photoUrl
          name
          bio
        }
      }
      partner {
        id
        name
        username
        photoUrl
        calendarLink
        slackDeepLink
        email
      }
    }
  }
`;

export const GET_COMPANY_SETUP_INTENT = gql`
  query CompanySetupIntent($where: CompanyWhereUniqueInput!) {
    getCompanySetupIntent(where: $where)
  }
`;

export const FIND_POSTS_QUERY = gql`
  query FindPosts($where: PostWhereInput, $cursor: PostWhereUniqueInput) {
    findPosts(where: $where, cursor: $cursor) {
      id
      note
      postType
      privacy
      originalPostId
      createdAt
      hasUserReacted
      author {
        name
        bio
        photoUrl
        role
        username
        inviter {
          id
          name
          username
          photoUrl
        }
        companies {
          name
          slug
          photoUrl
        }
      }
      huddle {
        id
        status
        hasUserOptedin
        hasUserReposted
        countHuddleReposts
        teamLead {
          username
          photoUrl
          name
          bio
        }
        originalPost {
          id
          _count {
            reposts
            reactions
          }
        }
        request {
          id
          slug
          name
          brief
          preferredDuration
          preferredStartDate
          equityOrCrypto
          status
          goals {
            id
            value
            slug
            shown
          }
          company {
            name
            slug
            photoUrl
            pitch
            videoUrl
            tags {
              slug
              value
              shown
            }
            customer {
              username
              name
              photoUrl
              partner {
                username
                name
                photoUrl
              }
            }
          }
        }
      }

      _count {
        reposts
        reactions
      }
    }
  }
`;

export const FEED_QUERY = gql`
  query Feed($cursor: PostWhereUniqueInput) {
    feed(cursor: $cursor) {
      id
      note
      postType
      privacy
      originalPostId
      createdAt
      hasUserReacted
      author {
        name
        bio
        photoUrl
        role
        username
        companies {
          name
          slug
          photoUrl
        }
      }
      huddle {
        originalPost {
          id
          _count {
            reposts
            reactions
          }
        }
        request {
          id
          slug
          name
          brief
          preferredDuration
          preferredStartDate
          equityOrCrypto
          status
          goals {
            id
            value
            slug
          }
          company {
            name
            slug
            photoUrl
            tags {
              slug
              value
              shown
            }
            customer {
              username
              name
              photoUrl
              partner {
                username
                name
                photoUrl
              }
            }
          }
        }
      }

      _count {
        reposts
        reactions
      }
    }
  }
`;

export const FIND_REQUEST_CREATED_POST = gql`
  query FindRequestCreatedPost($where: PostWhereInput) {
    findPosts(where: $where) {
      id
      _count {
        reposts
      }
    }
  }
`;

export const DOES_USER_HAVE_NOTIFICATIONS = gql`
  query DoesUserHaveNotifications {
    doesUserHaveNotifications
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query UserNotifications {
    notifications {
      id
      notficationType
      payload
      createdAt
      read
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    getCompaniesPaymentMethods {
      id
      type
      card {
        brand
        last4
        expMonth
        expYear
      }
      bankAccount {
        bankName
        last4
      }
    }
  }
`;

export const IS_USERNAME_AVAILABLE = gql`
  query isUserNameAvailable($input: String!) {
    isUserNameAvailable(input: $input)
  }
`;

export const GET_INTERESTS = gql`
  query GetInterests($where: InterestWhereInput) {
    findInterests(where: $where) {
      id
      value
      slug
      createdAt
      updatedAt
    }
  }
`;

export const GET_SKILLS = gql`
  query GetSkills($where: SkillWhereInput) {
    findSkills(where: $where) {
      id
      value
      slug
      createdAt
      updatedAt
    }
  }
`;

export const USER_GET_USERS = gql`
  query GetAllUsers(
    $getUsersCursor: UserWhereUniqueInput
    $getUsersWhere: UserWhereInput
    $getUsersTake: Int
    $getUsersOrderBy: [UserOrderByWithRelationInput!]
  ) {
    getAllUsers(cursor: $getUsersCursor, where: $getUsersWhere, take: $getUsersTake, orderBy: $getUsersOrderBy) {
      users {
        id
        name
        username
        photoUrl
        bio
        email
        isTeamLead
        isVenturePartner
        isCore
        createdAt
        userWorkReferences {
          name
        }
        inviter {
          id
          name
        }
        tags {
          slug
          value
        }
        socialLinks {
          platformName
          link
          username
        }
        builderOnboarding {
          state
          joinedSlack
          hadIntroMeeting
          viewedLearnModal
          stripeAccountId
          minimumRate
          verified
        }
      }
      count
    }
  }
`;

export const GET_RECOMMENDED_HUDDLES = gql`
  query Recommender {
    recommender {
      id
      status
      hasUserOptedin
      builders {
        user {
          username
          name
          photoUrl
          role
        }
      }
      _count {
        builders
        optIns
        posts
      }
      proposal {
        status
      }
      originalPost {
        id
        _count {
          reposts
          reactions
        }
      }
      request {
        id
        name
        slug
        brief
        status
        preferredStartDate
        preferredDuration
        cashBudget
        equityOrCrypto
        goals {
          id
          slug
          value
          shown
        }
        customerId
        company {
          name
          photoUrl
          slug
          tags {
            id
            slug
            value
            shown
          }
          pitch
          customer {
            username
            name
            photoUrl
            partner {
              username
              name
              photoUrl
            }
          }
        }
        _count {
          goals
        }
      }
    }
  }
`;

export const FIND_HUDDLES = gql`
  query FindHuddles(
    $where: HuddleWhereInput
    $orderBy: [HuddleOrderByWithRelationInput!]
    $cursor: HuddleWhereUniqueInput
    $take: Int
  ) {
    findHuddles(where: $where, orderBy: $orderBy, cursor: $cursor, take: $take) {
      huddles {
        id
        status
        createdAt
        updatedAt
        countHuddleReposts
        reservedStartDate
        officialStartDate
        request {
          name
          slug
          brief
          status
          preferredStartDate
          preferredDuration
          cashBudget
          equityOrCrypto
          createdAt
          roomLink
          partner {
            id
            phoneNumber
            photoUrl
            username
            calendarLink
            email
          }
          goals {
            id
            slug
            value
            shown
          }
          customerId
          companyId
          company {
            name
            photoUrl
            videoUrl
            slug
            pitch
            tags {
              id
              slug
              value
              shown
            }
            customer {
              username
              name
              photoUrl
              partner {
                username
                name
                photoUrl
              }
            }
            _count {
              goals
            }
          }
        }
        hasUserOptedin
        hasUserReposted
        teamLead {
          username
          photoUrl
          name
          bio
        }
        proposal {
          id
          status
          teamNote
          paySchedule {
            id
            payIntervals {
              id
              startDate
              endDate
              payments {
                id
                customerId
                totalAmount
                platformFee
                createdAt
                updatedAt
                paid
                invoiceSent
                payIntervalId
                invoiceUrl
              }
              payouts {
                id
                builderId
                totalAmount
                createdAt
                updatedAt
                hours
                paid
              }
            }
          }
          selectedOptIns {
            user {
              id
              username
              name
              photoUrl
              role
              bio
            }
            proposal_title
            proposal_userAccepted
            proposal_teamLead
          }
          proposalSentDate
          expirationDate
        }
        builders {
          user {
            username
            name
            photoUrl
            role
            bio
          }
        }
        originalPost {
          id
          _count {
            reposts
            reactions
          }
        }
        _count {
          builders
          optIns
          posts
        }
      }
      count
    }
  }
`;

export const GET_TEAM_MEMBER_CALENDLY_LINK = gql`
  query assignedTeamMemberCalendlyLink {
    assignedTeamMemberCalendlyLink
  }
`;

export const GET_HUDDLE_QUERY = gql`
  query GetHuddle($where: HuddleWhereInput!) {
    getHuddle(where: $where) {
      id
      status
      teamLead {
        username
        photoUrl
        name
        bio
      }
      hasUserReposted
      hasUserOptedin
      countHuddleReposts
      proposal {
        id
        status
        selectedOptIns {
          user {
            id
            username
            name
            photoUrl
          }
          proposal_title
          proposal_teamLead
        }
      }
      builders {
        user {
          username
          name
          photoUrl
          role
        }
      }
      originalPost {
        id
        _count {
          reposts
          reactions
        }
      }
      _count {
        builders
        optIns
        posts
      }
      request {
        id
        name
        slug
        brief
        status
        preferredStartDate
        preferredDuration
        cashBudget
        equityOrCrypto
        goals {
          id
          slug
          value
          shown
        }
        customerId
        company {
          name
          photoUrl
          pitch
          slug
          tags {
            id
            slug
            value
            shown
          }
          customer {
            username
            name
            photoUrl
            partner {
              username
              name
              photoUrl
            }
          }
        }

        _count {
          goals
        }
      }
    }
  }
`;

export const GET_TOKEN_REASONS = gql`
  query GetTokenReasons($where: TokenReasonWhereInput) {
    findTokenReasons(where: $where) {
      id
      value
      slug
    }
  }
`;

export const GET_TOKEN_FEED = gql`
  query GetTokenFeed {
    getTokens {
      id
      sender {
        id
        name
        photoUrl
      }
      receiver {
        id
        name
        username
        photoUrl
      }
      reason {
        id
        value
      }
      createdAt
    }
  }
`;

export const GET_PAY_INTERVAL = gql`
  query GetPayInterval($where: Float!) {
    getPayInterval(where: $where) {
      id
      payouts {
        id
        builderId
        totalAmount
        hours
        hourlyRate
        paid
      }
      payments {
        id
        customerId
        totalAmount
        platformFee
        paid
        invoiceSent
      }
    }
  }
`;

export const GET_PAY_INTERVALS = gql`
  query GetPayIntervals(
    $where: PayIntervalWhereInput
    $orderBy: [PayIntervalOrderByWithRelationInput!]
    $cursor: PayIntervalWhereUniqueInput
    $take: Int
  ) {
    getPayIntervals(where: $where, orderBy: $orderBy, cursor: $cursor, take: $take) {
      id
      paidInFull
      payments {
        id
        customerId
        paid
        totalAmount
      }
      payouts {
        builder {
          id
          name
          username
          email
        }
        firstPayoutComplete
        hourlyRate
        hours
        id
        paid
        paidDate
        totalAmount
        stripeTransferIds
      }
      endDate
      startDate
      paySchedule {
        id
        proposal {
          status
          huddle {
            id
            request {
              slug
              name
              company {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const FIND_LEAD_BOOKINGS_QUERY = gql`
  query FindLeadBookings($where: LeadBookingWhereInput) {
    findLeadBookings(where: $where) {
      leadBookings {
        id
        customerId
        hourlyRate
        paid
        date
        customer {
          name
          photoUrl
        }
        builderId
        builder {
          id
          isTeamLead
          name
          username
          photoUrl
          bio
          role
          calendarLink
          calendlyConnected
          skills {
            id
            value
          }
          interests {
            id
            value
          }
          tags {
            id
            slug
            value
            shown
          }
          builderOnboarding {
            minimumRate
          }
        }
      }
    }
  }
`;
