interface ProgressBarProps {
  progress: number;
  label?: string;
  showProgress?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, label, showProgress = true }: ProgressBarProps) => {
  return (
    <div className="relative pt-1">
      <div className="flex mb-1 items-center justify-between">
        <div>
          {label && (
            <span
              data-testid="label"
              className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200"
            >
              {label}
            </span>
          )}
        </div>

        {showProgress && (
          <div className="text-right">
            <span data-testid="progress-number" className="text-xs font-semibold inline-block text-blue-600">
              {progress}%
            </span>
          </div>
        )}
      </div>
      <div className="overflow-hidden h-1 mb-4 text-xs flex rounded bg-blue-200">
        <div
          data-testid="progress-bar"
          style={{ width: `${progress}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
