interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  color?: string;
}

const Divider: React.FC<DividerProps> = ({ text, color, ...rest }: DividerProps) => {
  return (
    <div data-testid="divider" {...rest}>
      <div className="border-b relative" style={{ borderColor: color ? color : undefined }}>
        <div className="absolute w-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 uppercase text-xs text-gray-500 font-medium">
          {text}
        </div>
      </div>
    </div>
  );
};
export default Divider;
