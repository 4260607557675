/* istanbul ignore file */
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFlagsmith } from 'flagsmith-react';
import {
  GetViewerQuery,
  GetViewerQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  Role,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from 'lib/generated/graphql';
import { SIGNIN_MUTATION, SIGN_UP_USER, UPDATE_USER_MUTATION } from 'lib/mutations';
import { GET_VIEWER } from 'lib/queries';
import LogRocket from 'logrocket';
import { useCallback, useEffect, useState } from 'react';
import AuthContext from 'src/shared-components/Auth/context';

const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    logout
  }
`;
export interface AuthProviderOptions {
  children?: React.ReactNode;
}

const AuthProvider = (opts: AuthProviderOptions): JSX.Element => {
  const { children } = opts;
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [signInMutation] = useMutation<LoginMutation, LoginMutationVariables>(SIGNIN_MUTATION);
  const [signUpMutation] = useMutation(SIGN_UP_USER);
  const [signOutMutation] = useMutation(SIGN_OUT_MUTATION);
  const [updateUserMutation] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION);
  const { identify, logout, setTraits } = useFlagsmith();

  const {
    data,
    loading: getUserLoading,
    error: getUserError,
    refetch,
  } = useQuery<GetViewerQuery, GetViewerQueryVariables>(GET_VIEWER);

  const signIn = useCallback(async input => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const { data } = await signInMutation({
          variables: {
            input,
          },
        });
        setUser(data?.login);
        await identify(JSON.stringify(data?.login?.id));
        await setTraits({
          email: data?.login?.email,
          name: data?.login?.name,
          role: data?.login?.role,
          isCore: data?.login?.isCore,
        });
        global?.analytics?.identify(data?.login?.id, {
          avatar: data?.login?.photoUrl,
          email: data?.login?.email,
          username: data?.login?.username,
          name: data?.login?.name,
          role: data?.login?.role,
          isCore: data?.login?.isCore,
          isPartner: data?.login?.isPartner,
          isTeamLead: data?.login?.isTeamLead,
          onboardingState:
            data?.login[
              data?.login?.role === Role.Builder
                ? data?.login?.builderOnboarding.state
                : data?.login?.customerOnboarding.state
            ],
          onboardingStateUpdated:
            data?.login[
              data?.login?.role === Role.Builder
                ? data?.login?.builderOnboarding.updatedAt
                : data?.login?.customerOnboarding.updatedAt
            ],
        });
        LogRocket.identify(JSON.stringify(data?.login.id), {
          name: data?.login?.name,
          email: data?.login.email,
          role: data?.login.role,
        });
        resolve(data?.login);
      } catch (error) {
        reject(error);
        // Silence errors
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  const signOut = useCallback(async () => {
    await signOutMutation();
    setUser(null);
    await logout();
  }, []);

  const signUp = useCallback(async registerData => {
    setIsLoading(true);
    try {
      await signUpMutation({
        variables: {
          signupInput: registerData,
        },
      });
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
    await refetch(); // If refetch doesn't work set the user with the result data of the mutation
    setTimeout(() => {
      setIsLoading(false);
    }, 333);
  }, []);

  const update = useCallback(async updateData => {
    setIsLoading(true);
    try {
      await updateUserMutation({
        variables: {
          updateData,
        },
      });
      await refetch();
      setTimeout(() => {
        setIsLoading(false);
      }, 333);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(getUserLoading);
  }, [getUserLoading]);

  useEffect(() => {
    // TODO: return an error code from the server
    if (getUserError && getUserError.message !== 'You must be logged in!') {
      // console.error('Get user:', getUserError);
    }
  }, [getUserError]);

  useEffect(() => {
    setUser(data?.me);
    (async () => {
      await identify(JSON.stringify(data?.me?.id));
      global?.analytics?.identify(data?.me?.id, {
        avatar: data?.me?.photoUrl,
        email: data?.me?.email,
        username: data?.me?.username,
        name: data?.me?.name,
        role: data?.me?.role,
        onboardingState:
          data?.me[
            data?.me?.role === Role.Builder ? data?.me?.builderOnboarding.state : data?.me?.customerOnboarding.state
          ],
        onboardingStateUpdated:
          data?.me[
            data?.me?.role === Role.Builder
              ? data?.me?.builderOnboarding.updatedAt
              : data?.me?.customerOnboarding.updatedAt
          ],
      });
      LogRocket.identify(JSON.stringify(data?.me.id), {
        name: data?.me?.name,
        email: data?.me.email,
        role: data?.me.role,
      });
    })();
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        update,
        refetch,
        isLoading,
        user,
        isLoggedIn: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
