/* eslint-disable react/display-name */
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from 'public/images/icons/close.svg';
import {
  Children,
  cloneElement,
  forwardRef,
  Fragment,
  isValidElement,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Divider } from 'src/shared-components';
import LoadingOverlay from 'src/shared-components/Loading/LoadingOverlay';

interface ModalProps {
  closeOnBackdropClick?: boolean;
  showCloseButton?: boolean;
  backgroundColor?: 'gray-500' | 'black';
  backgroundOpacity?: 75 | 100;
  children: ReactNode;
  closeButtonForStepper?: boolean;
  defaultOpen?: boolean;
  maxHeight?: boolean;
  needsPadding?: boolean;
  onVisibilityChange?: (open: boolean) => void;
}

const Modal = forwardRef(
  (
    {
      closeOnBackdropClick = true,
      showCloseButton = false,
      backgroundColor = 'gray-500',
      backgroundOpacity = 75,
      closeButtonForStepper = false,
      defaultOpen = false,
      children,
      maxHeight = true,
      needsPadding = true,
      onVisibilityChange = () => {},
    }: ModalProps,
    ref,
  ) => {
    const backdropClassName = `fixed inset-0 bg-${backgroundColor} bg-opacity-${backgroundOpacity} transition-opacity`;
    const [open, setOpen] = useState(defaultOpen);
    const [isLoading, setIsLoading] = useState(false);

    const divRef = useRef(null);

    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
        onVisibilityChange(true);
      },
      close() {
        setOpen(false);
        onVisibilityChange(false);
      },
    }));

    const childrenWithProps = Children.map(children, child => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (isValidElement(child)) {
        return cloneElement(child, { setIsLoading: setIsLoading });
      }
      return child;
    });

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto font-DMSans"
          initialFocus={divRef}
          onClose={setOpen}
        >
          <div
            ref={divRef}
            className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <div className="bg-gray-500 bg-black bg-opacity-75 bg-opacity-100" />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {closeOnBackdropClick ? (
                <Dialog.Overlay className={backdropClassName} />
              ) : (
                <div className={backdropClassName} />
              )}
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                data-testid="modal-content"
                className={`inline-block align-bottom bg-white rounded-lg  text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-fit overflow-auto 
                ${maxHeight && 'max-h-[66vh]'}
                ${needsPadding ? ' pt-5 pb-4 sm:p-6' : 'sm:p-0 pt-0 pb-0 '}
                `}
              >
                {isLoading && <LoadingOverlay />}

                {/* sm:max-w-md md:max-w-xl sm:w-full */}
                {showCloseButton && (
                  <div className="mb-4">
                    <CloseIcon
                      data-testid="close-icon"
                      fill="#595959"
                      onClick={() => setOpen(false)}
                      className="cursor-pointer mb-5 ml-auto"
                    />
                    <Divider />
                  </div>
                )}
                {closeButtonForStepper && (
                  <div className="relative">
                    <CloseIcon
                      data-testid="close-icon"
                      fill="#595959"
                      onClick={() => setOpen(false)}
                      className="cursor-pointer absolute right-3 z-20"
                    />
                  </div>
                )}
                {childrenWithProps}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  },
);

export default Modal;
