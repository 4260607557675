import React, { useEffect, useState } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | 'primary'
    | 'secondary'
    | 'black'
    | 'gray'
    | 'white'
    | 'light-blue'
    | 'light-green'
    | 'deep-black'
    | 'green'
    | 'dark'
    | 'light-purple'
    | 'dark-purple'
    | 'dark-yellow'
    | 'dark-red';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: React.ReactNode;
  fullWidth?: boolean;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  roundness?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  borderColor?: 'huddleBlack' | 'white' | 'black' | '';
  shadow?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  size = 'md',
  children,
  fullWidth = false,
  type = 'submit',
  disabled = false,
  loading = false,
  roundness = 'md',
  shadow = false,
  borderColor,
  ...rest
}) => {
  const [_size, setSize] = useState<string>();
  const [_color, setColor] = useState<string>('bg-navy text-white');
  const [_roundness, setRoundness] = useState<string>();
  const [hoverColor, setHoverColor] = useState<string>('hover:bg-navy-700');
  const [disabledColor, setDisabledColor] = useState<string>('bg-navy-200');
  const [disabledHoverColor, setDisabledHoverColor] = useState<string>('hover:bg-navy-200');

  useEffect(() => {
    switch (size) {
      case 'xs':
        setSize('px-3.5 py-1.5 text-xs font-normal');
        break;
      case 'sm':
        setSize('px-4 py-2 text-sm leading-4 font-normal');
        break;
      case 'md':
        setSize('px-6 py-2 text-sm font-normal');
        break;
      case 'lg':
        setSize('px-7 h-[54px] text-base font-normal');
        break;
      case 'xl':
        setSize('px-8 h-[56px] text-[18px] font-bold');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [size]);

  useEffect(() => {
    switch (color) {
      case 'primary':
        setColor('bg-navy text-white border-navy');
        setHoverColor('hover:bg-navy-700');
        setDisabledColor('bg-navy-200 hover:bg-navy-200 border-navy-200');
        setDisabledHoverColor('hover:bg-navy-200');
        break;
      case 'secondary':
        setColor('bg-lightBlue text-navy border-lightBlue');
        setHoverColor('hover:bg-lightBlue-700');
        setDisabledColor('hover:bg-lightBlue-200');
        setDisabledHoverColor('hover:bg-lightBlue-200');
        break;
      case 'white':
        setColor(`bg-white text-gray-800 border-${borderColor || '-gray-900'}`);
        setHoverColor('hover:bg-gray-100');
        setDisabledColor('hover:bg-gray-50');
        setDisabledHoverColor('hover:bg-gray-100');
        break;
      case 'gray':
        setColor(`bg-gray-200 text-gray-800 border-${borderColor || '-gray-200'}`);
        setHoverColor('hover:bg-gray-300');
        setDisabledHoverColor('hover:bg-gray-200');
        break;
      case 'black':
        setColor(`bg-huddleBlack text-white border-${borderColor}`);
        setHoverColor('hover:opacity-80');
        break;
      case 'deep-black':
        setColor(`bg-huddleDeepBlack text-white border-${borderColor}`);
        setDisabledColor('bg-[#C6C6C6]');
        setHoverColor('hover:opacity-80');
        break;
      case 'light-blue':
        setColor('bg-[#E9F3FF] text-[#266FC6] border-2 border-[#68AEFF]');
        setHoverColor('hover:opacity-80');
        break;
      case 'light-green':
        setColor('bg-[#AEBBBA] sm:bg-[#D3E3E2] text-[#3A4444] border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'green':
        setColor('bg-[#239F73] sm:bg-[#239F73] text-white border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'dark':
        setColor('bg-[#493C63] sm:bg-[#493C63] text-white border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'light-purple':
        setColor('bg-[#ddd9e7] sm:bg-[#ddd9e7] text-white border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'dark-purple':
        setColor('bg-purple-950 text-purple-50 border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'dark-yellow':
        setColor('bg-yellow-900 text-yellow-50 border-0');
        setHoverColor('hover:opacity-80');
        break;
      case 'dark-red':
        setColor('bg-[#6D2C35] text-[#e2d5d7] border-0');
        setHoverColor('hover:opacity-80');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [color]);

  useEffect(() => {
    switch (roundness) {
      case 'sm':
        setRoundness('rounded-sm');
        break;
      case 'md':
        setRoundness('rounded-md');
        break;
      /* istanbul ignore next */
      case 'lg':
        setRoundness('rounded-lg');
        break;
      case 'xl':
        setRoundness('rounded-[1rem]');
        break;
      case 'full':
        setRoundness('rounded-full');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  }, [roundness]);

  return (
    <button
      type={type}
      disabled={disabled || loading}
      {...rest}
      className={`${rest.className} ${_size} ${_color} ${_roundness} leading-snug border ${
        loading || disabled ? `cursor-not-allowed ${disabledColor}` : _color
      } ${
        loading || disabled ? `cursor-not-allowed ${disabledHoverColor}` : hoverColor
      }  focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-gray-500 ${
        fullWidth ? 'w-full text-center' : 'inline-flex items-center'
      } ${shadow ? 'shadow' : ''}`}
    >
      {/* TODO: create tailwind class for negative values? */}
      {loading ? (
        <>
          <div className="relative">
            <div className="">{children}</div>
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

export default Button;
