import Image from 'next/image';
import React, { useEffect, useState } from 'react';

export interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: string;
  size?: 'xxsmall' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'custom-pay-small';
  roundness?: 'md' | 'full';
  dropShadow?: boolean;
  isLink?: boolean;
  username?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  image,
  size = 'md',
  roundness = 'full',
  dropShadow = false,
  isLink = false,
  username = '',

  ...rest
}: AvatarProps) => {
  const [_size, setSize] = useState<number | string>();
  const [_roundness, setRoundness] = useState<string>();
  const DEFAULT_USER_AVATAR = process.env.DEFAULT_USER_AVATAR;
  const usernameFirstLetter = username?.substring(0, 1).toUpperCase();

  const DEFAULT_COMPANY_AVATAR = process.env.DEFAULT_COMPANY_AVATAR || '';
  const DEFAULT_COMPANY_AVATAR1 = process.env.DEFAULT_COMPANY_AVATAR1 || '';
  const DEFAULT_COMPANY_AVATAR2 = process.env.DEFAULT_COMPANY_AVATAR2 || '';
  const DEFAULT_COMPANY_AVATAR3 = process.env.DEFAULT_COMPANY_AVATAR3 || '';

  const DEFAULT_USER_AVATAR1 = process.env.DEFAULT_USER_AVATAR1 || '';
  const DEFAULT_USER_AVATAR2 = process.env.DEFAULT_USER_AVATAR2 || '';
  const DEFAULT_USER_AVATAR3 = process.env.DEFAULT_USER_AVATAR3 || '';

  const randomNum = () => {
    if (username && username.length > 0) {
      return username.charCodeAt(0) % 4;
    } else {
      return 0;
    }
  };

  const randomNumber = randomNum();

  const generateUserBackground = num => {
    // Switch statement to handle the random number
    switch (num) {
      case 0:
        return DEFAULT_USER_AVATAR;
      case 1:
        return DEFAULT_USER_AVATAR1;
      case 2:
        return DEFAULT_USER_AVATAR2;
      case 3:
        return DEFAULT_USER_AVATAR3;
      default:
        return DEFAULT_USER_AVATAR;
    }
  };

  const generateTextStyle = num => {
    switch (num) {
      case 0:
        return `text-yellow-900 text-${size} not-italic font-bold`;
      case 1:
        return `text-gray-800 text-${size} not-italic font-bold`;
      case 2:
        return `text-red-900 text-${size} not-italic font-bold`;
      case 3:
        return `text-indigo-900 text-${size} not-italic font-bold`;
      default:
        return `text-gray-800 text-${size} not-italic font-bold`;
    }
  };

  const generateCompanyBackground = num => {
    // Create an array of the numbers 1, 2, 3, and 4
    // Switch statement to handle the random number
    switch (num) {
      case 0:
        return DEFAULT_COMPANY_AVATAR;
      case 1:
        return DEFAULT_COMPANY_AVATAR1;
      case 2:
        return DEFAULT_COMPANY_AVATAR2;
      case 3:
        return DEFAULT_COMPANY_AVATAR3;
      default:
        return DEFAULT_COMPANY_AVATAR1;
    }
  };

  useEffect(() => {
    switch (size) {
      case 'xxsmall':
        setSize(9);
        setRoundness('xs');
        break;
      case 'xxs':
        setSize('[20px]');
        setRoundness('xs');
        break;
      case 'xs':
        setSize(11);
        setRoundness('xl');
        break;
      case 'sm':
        setSize(16);
        setRoundness('2xl');
        break;
      case 'md':
        setSize(24);
        setRoundness('3xl');
        break;
      case 'lg':
        setSize(32);
        setRoundness('5xl');
        break;
      case 'custom-pay-small':
        setSize(9);
        setRoundness('xl');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
    if (roundness === 'full') {
      setRoundness(roundness);
    }
  }, [size, roundness]);

  return (
    <div className="inline-flex items-center text-center justify-center relative">
      <div
        data-testid="avatar-container"
        className={`h-${_size} w-${_size} relative mx-auto ${isLink && 'hover:opacity-80'} position: relative;`}
        style={{ filter: dropShadow ? 'drop-shadow(0px 8px 24px rgba(1, 0, 63, 0.16))' : 'unset' }}
      >
        {image === DEFAULT_USER_AVATAR ? (
          <Image
            layout="fill"
            objectFit="cover"
            data-testid="avatar"
            className={`${rest.className} inline-block rounded-${_roundness} -z-1`}
            src={generateUserBackground(randomNumber)}
            alt=""
          />
        ) : image === DEFAULT_COMPANY_AVATAR ? (
          <Image
            layout="fill"
            objectFit="cover"
            data-testid="avatar"
            className={`${rest.className} inline-block rounded-${_roundness} -z-1`}
            src={generateCompanyBackground(randomNum())}
            alt=""
          />
        ) : (
          image && (
            <Image
              layout="fill"
              objectFit="cover"
              data-testid="avatar"
              className={`${rest.className} inline-block rounded-${_roundness} -z-1`}
              src={image}
              alt=""
            />
          )
        )}

        <div className="hidden h-9 w-9 h-32 w-32 h-24 w-24 w-16 h-16 h-11 w-11 h-6 w-6 h-[10px] w-[10px] rounded-xs rounded-xl rounded-5xl rounded-2xl rounded-xl" />
      </div>
      {image === DEFAULT_USER_AVATAR ? (
        <p className={`${generateTextStyle(randomNumber)} italic absolute`}>{usernameFirstLetter}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Avatar;
