import Link from 'next/link';
import { useEffect, useState } from 'react';
import { getValidURL } from 'utils/common';
import { SocialLink } from '../../../lib/generated/graphql';

const SocialLinkComponent = ({ socialLink }: { socialLink: SocialLink }) => {
  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    if (socialLink.platformName === 'Twitter') {
      setIcon('twitter');
    } else if (socialLink.platformName === 'Medium') {
      setIcon('medium');
    } else if (socialLink.platformName === 'LinkedIn') {
      setIcon('linkedin');
    } else {
      setIcon('web');
    }
  }, []);

  return (
    <div className="w-4 h-4">
      <Link href={getValidURL(socialLink.link) || ''}>
        <a target="_blank">
          <img src={`/images/icons/links/${icon}.svg`} />
        </a>
      </Link>
    </div>
  );
};

export default SocialLinkComponent;
